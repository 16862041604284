import {
  Button,
  Box,
  Autocomplete,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useForm, register, watch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { CandidateJobPreferencesRule } from 'models/CandidateProfileSchema';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const dayjs = require('dayjs');
import { addJobPreferance, getJobPreferanceDetails, addSingleJobPreference } from 'api/users';
import { useNavigate } from 'react-router-dom';
import { SplashScreen } from 'components/loading-screen';
import { formatCurrency } from 'utils/Utils';
import cityData from 'data/city.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

const JobPreferences = () => {
  const { enqueueSnackbar } = useSnackbar();
  const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isBuyout, setIsBuyout] = useState(false);
  const [onSelect, setOnSelect] = useState(0);

  const workModelFormat = ['In office', 'Work from Home', 'Hybrid'];
  const jobType = ['Full time', 'Part time', 'Temporary', 'Internship', 'Trainee', 'Freelancer (Self employed)'];
  const preferredJobShifts = ['Any', 'Daytime', 'Evening', 'Night'];

  const defaultValues = {
    notice_period: '',
    buyout_period: '',
    is_negotiable: false,
    total_ctc: null,
    compensation: '',
    fixed_ctc: null,
    variable_ctc: null,
    other_ctc: '',
    expected_ctc: '',
    work_format: ['In office'],
    job_type: ['Full time'],
    preferred_job_shifts: ['Daytime'],
    prefered_location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(CandidateJobPreferencesRule(0, isBuyout)),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { touchedFields, errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const fixed = watch('fixed_ctc');
  const variable = watch('variable_ctc');

  useEffect(() => {
    const fetchjobs = async () => {
      try {
        const response = await getJobPreferanceDetails();
        if (response?.success && response.data) {
          Object.keys(defaultValues).forEach((field) => {
            setValue(field, response.data[field]);
          });
          setIsBuyout(response?.data?.is_negotiable);
          setOnSelect(response?.data?.prefered_location?.length);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchjobs();
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      const preferredLocations = value?.prefered_location;
      if (Array.isArray(preferredLocations)) {
        if (preferredLocations.length > 5) {
          const limitedLocations = preferredLocations.slice(0, 5);
          setValue('prefered_location', limitedLocations);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  useEffect(() => {
    const total = parseInt(fixed || 0) + parseInt(variable || 0);
    setValue('total_ctc', total);
  }, [fixed, variable]);

  useEffect(() => {
    if (onSelect > 5) setTimeout(() => setOnSelect(5), 3000)
  }, [onSelect]);

  const onSubmit = handleSubmit(async (formData) => {
    const response = await addJobPreferance(formData);
    if (response?.success === true && response.data) {
      navigate('/job-application');
      enqueueSnackbar(response.message, { variant: 'success' });
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  });

  const handleChange = (name, previousValue, value, allValue) => {
    if (value?.includes('Any') && !previousValue?.includes('Any')) {
      setValue(name, allValue);
      handleSingleChange({ [name]: allValue });
    } else if (previousValue?.includes('Any') && !value?.includes('Any')) {
      setValue(name, []);
      handleSingleChange({ [name]: [] });
    } else {
      setValue(name, value);
      handleSingleChange({ [name]: value });
    }
  }

  const handleSingleChange = async (data) => {
    const response = await addSingleJobPreference(data);
    if (response?.success === true && response.data) {
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  const linkedinIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" className="back-button" src={backIcon} />
    </Icon>
  );

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box className="page-content">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Container maxWidth="xl">
          <Box className="job-preferences-page">
            <Box className="job-preferences-content-wrapper">
              <Box className="current-job-det" mb={1}>
                <Box mb={3}>
                  <Typography className="basic-profile-main-heading" variant="h6" gutterBottom>
                    Update Job Details & Preferences
                  </Typography>
                  <Typography className="step-info" variant="body2" gutterBottom>
                    These details would help us match the most suitable jobs for your profile
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Current Job Details
                  </Typography>
                  {/* <Typography className="step-info" variant="body2">
                    These details would help recruiters understand & connect with you
                  </Typography> */}
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {/* notice period */}
                    <FormControl fullWidth size="small" error={!!errors.notice_period}>
                      <InputLabel id="notice_period">Notice Period</InputLabel>
                      <Controller
                        control={control}
                        name="notice_period"
                        defaultValue={defaultValues?.buyout_period || ''}
                        render={({ field }) => (
                          <Select
                            {...field}
                            {...register('notice_period')}
                            labelId="notice_period"
                            label="Notice Period"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              handleSingleChange({ notice_period: e.target.value });
                            }
                            }
                          >
                            <MenuItem value="0 days (immediate)">0 days (immediate)</MenuItem>
                            <MenuItem value="15">15 Days</MenuItem>
                            <MenuItem value="30">30 Days</MenuItem>
                            <MenuItem value="45">45 Days</MenuItem>
                            <MenuItem value="60">60 Days</MenuItem>
                            <MenuItem value="75">75 Days</MenuItem>
                            <MenuItem value="90">90 Days</MenuItem>
                            <MenuItem value="90+">90+ Days</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.notice_period && <FormHelperText error>{errors.notice_period?.message}</FormHelperText>}
                    </FormControl>
                    {/* is negotiable */}
                    <FormControl error={!!errors.is_negotiable} className="neogtiable-chk" size="small">
                      <Controller
                        name="is_negotiable"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                {...register('is_negotiable')}
                                id="is_negotiable"
                                name="is_negotiable"
                                checked={isBuyout}
                                onChange={(event) => {
                                  let dataToUpdate = { is_negotiable: event.target.checked };
                                  if (!event.target.checked) {
                                    setValue('buyout_period', '');
                                    dataToUpdate = { ...dataToUpdate, buyout_period: '' };
                                  }
                                  field.onChange(event.target.checked);
                                  setIsBuyout(event.target.checked);
                                  handleSingleChange(dataToUpdate);
                                }}
                              />
                            }
                            label="Notice period is negotiable"
                            id="is_negotiable"
                            sx={{ paddingLeft: 2 }}
                          />
                        )}
                      />
                      {errors.is_negotiable && (
                        <Typography variant="caption" color="error">
                          {errors.is_negotiable.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {/* buyout period */}
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="buyout_period"
                        defaultValue={defaultValues?.buyout_period || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="buyout_period"
                            label="If notice period is negotiable, please state how much."
                            name="buyout_period"
                            disabled={!isBuyout}
                            variant="outlined"
                            size="small"
                            error={isBuyout && !!errors.buyout_period}
                            helperText={isBuyout && errors.buyout_period?.message}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              handleSingleChange({ buyout_period: e.target.value });
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1rem' }} />

              <Box className="annual-compensation" mb={3}>
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Annual Compensation
                  </Typography>
                  <Typography className="step-info" variant="body2">
                    Please tell us your annual compensation with current company
                  </Typography>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          {/* fixed ctc */}
                          <FormControl fullWidth error={!!errors?.fixed_ctc} >
                            <Controller
                              control={control}
                              name="fixed_ctc"
                              defaultValue={defaultValues?.fixed_ctc || ''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="fixed_ctc"
                                  name="fixed_ctc"
                                  label="Fixed Component"
                                  variant="outlined"
                                  size="small"
                                  value={field.value ? formatCurrency(field.value) : ''}
                                  error={!!errors.fixed_ctc}
                                  onChange={(e) => {
                                    const rawValue = parseInt(e.target.value?.replace(/,/g, '')) || null;
                                    const variableAmt = parseInt(getValues('variable_ctc')) || null;

                                    field.onChange(rawValue);
                                    handleSingleChange({ fixed_ctc: rawValue, total_ctc: (rawValue + variableAmt) });
                                  }}
                                />
                              )}
                            />
                            {errors?.fixed_ctc && <FormHelperText error>{errors?.fixed_ctc?.message}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {/* variable ctc */}
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name="variable_ctc"
                              defaultValue={defaultValues?.variable_ctc || ''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="variable_ctc"
                                  name="variable_ctc"
                                  label="Variable Component"
                                  variant="outlined"
                                  size="small"
                                  value={field.value ? formatCurrency(field.value) : ''}
                                  error={!!errors.variable_ctc}
                                  helperText={errors.variable_ctc?.message}
                                  onChange={(e) => {
                                    const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                                    const fixedAmt = parseInt(getValues('fixed_ctc')) || null;
                                    field.onChange(rawValue);
                                    handleSingleChange({ variable_ctc: rawValue, total_ctc: (rawValue + fixedAmt) });
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* other ctc */}
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="other_ctc"
                          defaultValue={defaultValues?.other_ctc || ''}
                          rules={{
                            validate: (value) => {
                              const wordCount = value.trim().split(/\s+/).length;
                              return wordCount <= 25 || "Maximum 25 words allowed.";
                            }
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="other_ctc"
                              name="other_ctc"
                              label="Other Perks"
                              variant="outlined"
                              size="small"
                              defaultValue={defaultValues?.other_ctc || ''}
                              error={!!errors.other_ctc}
                              helperText={errors.other_ctc?.message}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const words = inputValue.trim().split(/\s+/);
                                if (words.length <= 25) {
                                  field.onChange(inputValue);
                                  clearErrors('other_ctc');
                                } else {
                                  setError('other_ctc', { message: "Maximum 25 words allowed." });
                                }
                                handleSingleChange({ other_ctc: e.target.value });
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box className="compensation-det-block">
                        {watch('total_ctc') && watch('total_ctc') > '0' ?
                          <Box className="after-comp-amt">
                            <Typography className="comp-text">Your Total Annual Compensation</Typography>
                            <Typography className="comp-amt" mt={2}>
                              ₹{watch('total_ctc') && `${new Intl.NumberFormat('en-IN').format(watch('total_ctc'))}`}
                            </Typography>
                            <Typography className="comp-text" sx={{ height: 12, fontSize: 10 }}>{watch('other_ctc') && `Along with ${watch('other_ctc')}`}</Typography>
                          </Box>
                          :
                          <Box className="before-comp-amt" >
                            <Typography className="comp-text">
                              Please enter your compensation details on the left
                            </Typography>
                          </Box>}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1.5rem' }} />

              <Box className="job-preferences">
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Job Preferences
                  </Typography>
                  <Typography className="step-info" variant="body2">
                    This would help us identify the best suited jobs for you
                  </Typography>
                </Box>

                <Box>
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={6}>
                      {/* expected ctc */}
                      <FormControl fullWidth size="small" error={!!errors.expected_ctc}>
                        <Controller
                          control={control}
                          name="expected_ctc"
                          defaultValue={defaultValues?.expected_ctc || ''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="expected_ctc"
                              name="expected_ctc"
                              label="Annual Expected Total Compensation (fixed + variable)"
                              variant="outlined"
                              size="small"
                              value={field.value ? formatCurrency(field.value) : ''}
                              error={!!errors.expected_ctc}
                              helperText={errors.expected_ctc?.message}
                              onChange={(e) => {
                                const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                                field.onChange(rawValue);
                                handleSingleChange({ expected_ctc: rawValue });
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* work format */}
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="work_format"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={workModelFormat}
                              getOptionLabel={(option) => option}
                              onChange={(event, value) => {
                                field.onChange(value);
                                handleSingleChange({ work_format: value });
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Preferred Work Format"
                                  error={!!errors.work_format}
                                  helperText={errors.work_format?.message}
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* job type */}
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="job_type"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={jobType}
                              getOptionLabel={(option) => option}
                              onChange={(event, value) => {
                                field.onChange(value);
                                handleSingleChange({ job_type: value });
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Preferred Job Type"
                                  error={!!errors.job_type}
                                  helperText={errors.job_type?.message}
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* job shift */}
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="preferred_job_shifts"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={preferredJobShifts}
                              getOptionLabel={(option) => option}
                              onChange={(event, value) => {
                                handleChange(field?.name, field?.value, value, preferredJobShifts);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Preferred Working Hours"
                                  variant="outlined"
                                  error={!!errors.preferred_job_shifts}
                                  helperText={errors.preferred_job_shifts?.message}
                                />
                              )}
                            />
                          )}
                        /><FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                      {/* location */}
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="prefered_location"
                          render={({ field }) => (
                            <TypeAhead
                              {...field}
                              {...register('prefered_location')}
                              label="Preferred Locations (select upto 5)"
                              multiple={true}
                              selectAsCheckbox={true}
                              isCloseHide={true}
                              data={[{ name: 'Any', value: 'Any' }, ...cityData]}
                              selectionKey="value"
                              displayTextKey="name"
                              maxSelection={5}
                              onChange={(e, dataObjs, values) => {
                                if (values?.length <= 5) {
                                  setOnSelect(values?.length);
                                  handleSingleChange({ prefered_location: values });
                                } else {
                                  setOnSelect(onSelect + 1);
                                }
                              }}
                            />
                          )}
                        />
                        {(onSelect && onSelect > 5) ? <FormHelperText error>You can select up to 5 locations only</FormHelperText> : null}
                        <FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1.5rem' }} />

              <Button
                className="next-btn"
                variant="contained"
                size="large"
                sx={{ backgroundColor: '#1575C6!important' }}
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Container>
      </FormProvider>
    </Box>
  );
};

export default JobPreferences;
