import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  SvgIcon,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import JobFullDetails from './JobFullDetails';
import { getApplicationList, setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { getTimeDifferenceString, changeNameFormat, formatNumber } from 'utils/Utils';
import { SplashScreen } from 'components/loading-screen';
import FilterSearch from './FilterSearch';
import EmptyState from './EmptyState';
import { useAuth } from 'hooks/useAuthContext';

const MyJobs = ({ jobData, tab, changeTabForDownload, showExternalStatus, externalJob, onSelectTab }) => {
  const { jobApply, setJobApply } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
  const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
  const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
  const [value, setValue] = React.useState(tab || 'saved-jobs');
  const [setActiveId, setSetActiveId] = useState(jobData?._id || value === 'saved-jobs' && jobApply?.job?._id || null);
  const [loading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(jobData || value === 'saved-jobs' && jobApply?.job || null);
  const [savedApplications, setSavedApplications] = useState([]);
  const [appliedApplications, setAppliedApplications] = useState([]);
  const [showExternalJobStatus, setShowExternalJobStatus] = useState(showExternalStatus || '');
  const [alignment, setAlignment] = React.useState('all');
  const [showApplication, setShowApplication] = useState([]);
  const [showExternalJob, setShowExternalJob] = useState(externalJob || false);
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (flag, isStatusChanged = false, search = null, filters = {}, sort) => {
    try {
      const response = await getApplicationList({ search, filters, sort });
      if (response?.success && response?.data) {
        const savedApps = response?.data?.filter(
          (application) => application?.status === 'saved' && application?.is_hide != true
        );
        let appliedApps = response?.data?.filter((application) => application?.status !== 'saved');
        setSavedApplications(savedApps);
        setAppliedApplications(appliedApps);
        setShowApplication(appliedApps);
        if (!isStatusChanged) {
          if (flag) {
            if (savedApps?.length > 0 && value === 'saved-jobs') {
              setSelectedApplication(savedApps[0]);
              setValue('saved-jobs');
              setShowEmptyState(savedApps?.length === 0 && true);
              setSetActiveId(savedApps[0]?._id);
            } else if (appliedApps?.length > 0) {
              setSelectedApplication(appliedApps[0]);
              setSetActiveId(appliedApps[0]?._id);
              setValue('applied-jobs');
              setShowEmptyState(appliedApps?.length === 0 && true);
            } else {
              setSelectedApplication(savedApps[0]);
              setValue('saved-jobs');
              setShowEmptyState(savedApps?.length === 0 && true);
              setSetActiveId(savedApps[0]?._id);
            }
          } else if (jobData) {
            setSelectedApplication(jobData);
            setSetActiveId(jobData?._id);
            setValue(tab);
          } else if (!selectedApplication) {
            if (savedApps?.length > 0) {
              setSelectedApplication(savedApps[0]);
              setSetActiveId(savedApps[0]?._id);
              setValue('saved-jobs');
              setShowEmptyState(savedApps?.length === 0 && true);
            } else if (appliedApps?.length > 0) {
              setSelectedApplication(appliedApps[0]);
              setSetActiveId(appliedApps[0]?._id);
              setValue('applied-jobs');
              setShowEmptyState(appliedApps?.length === 0 && true);
            } else {
              if (value === 'applied-jobs') {
                setShowEmptyState(appliedApps && appliedApps?.length === 0 && true);
              } else {
                setShowEmptyState(savedApps && savedApps?.length === 0 && true);
              }
            }
          }
        }
        if (showExternalJob) {
          const externaljob = response?.data?.filter((application) => application?.is_external_job_application === true && application?.status === 'in-progress');
          setAppliedApplications(appliedApps);
          setShowApplication(externaljob);
          setSelectedApplication(externaljob[0]);
          setSetActiveId(externaljob[0]?._id);
          setValue('applied-jobs');
          setShowEmptyState(appliedApps?.length === 0 && true);
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setJobApply(null);
    setValue(newValue);
    setShowExternalJobStatus('');
    if (showExternalJob) {
      setShowExternalJob(false);
      setShowApplication(appliedApplications);
    }
    if (newValue === 'applied-jobs') {
      setShowEmptyState(appliedApplications?.length === 0 ? true : false);
      setSelectedApplication(appliedApplications[0]);
      setSetActiveId(appliedApplications[0]?._id);
    } else {
      setShowEmptyState(savedApplications?.length === 0 ? true : false);
      setSelectedApplication(savedApplications[0]);
      setSetActiveId(savedApplications[0]?._id);
      setAlignment('all');
    }
  };

  const handleSelectedApplication = () => {
    fetchData(false, true);
  };


  const handleApplicationClick = (application) => {
    setJobApply(null);
    setShowExternalJobStatus('');
    setSelectedApplication(application);
    setSetActiveId(application?._id);
  };

  const handleAppliedJob = () => {
    setShowExternalJobStatus('');
    const flag = 'applied';
    fetchData(flag);
  };

  const recommendedChip = (score) => {
    if (score > 3) {
      return (
        <Chip
          className="chip-job-matching chip-suitable"
          avatar={<Avatar alt="Natacha" src={suitableIcon} />}
          label="Highly Suitable Job For You"
          size="small"
        />
      );
    } else if (score < 2) {
      return (
        <Chip
          className="chip-job-matching chip-not-matching"
          avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
          label="Job Not Matching Your Profile"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          className="chip-job-matching chip-mod-suitable"
          avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
          label="Moderately Suitable For You"
          size="small"
        />
      );
    }
  };
  const handleDownloadResume = (applicationDataForDownloadResume) => {
    changeTabForDownload(applicationDataForDownloadResume, '3');
  };
  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      switch (newAlignment) {
        case 'internal-job':
          const internalApps = appliedApplications?.filter(
            (application) => application?.is_external_job_application !== true
          );
          setShowApplication(internalApps);
          setSelectedApplication(internalApps[0]);
          setSetActiveId(internalApps[0]?._id);
          break;
        case 'external-job':
          const externalApps = appliedApplications?.filter(
            (application) => application?.is_external_job_application === true
          );
          setShowApplication(externalApps);
          setSelectedApplication(externalApps[0]);
          setSetActiveId(externalApps[0]?._id);
          break;
        default:
          setShowApplication(appliedApplications);
          setSelectedApplication(appliedApplications[0]);
          setSetActiveId(appliedApplications[0]?._id);
          break;
      }
    }
  };

  const handleTabToMyJob = () => {
    onSelectTab('2');
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100vh - 110px)',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TabContext value={value}>
            <Box className="my-jobs-tabs">
              <Container maxWidth="xl">
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label={`Saved Jobs (${savedApplications?.length || 0})`} value="saved-jobs" />
                  <Tab label={`Applied Jobs (${showExternalJob ? showApplication?.length || 0 : appliedApplications?.length || 0})`} value="applied-jobs" />
                </TabList>
              </Container>
            </Box>
            {(savedApplications?.length > 0 || showApplication?.length > 0) && !showEmptyState &&
              <Box>
                <TabPanel value="saved-jobs" sx={{ padding: 0 }}>
                  <Box className="active-jobs-list">
                    {/* Filter / Search Component */}
                    <FilterSearch fetchJobs={fetchData} activeTab={value} />

                    <Box className="active-jobs-container" sx={{ paddingBottom: '6rem!important' }}>
                      {savedApplications?.length > 0 && (
                        savedApplications?.map((application, index) => (
                          <Card
                            key={index}
                            className={`job-card-view ${setActiveId && application?._id === setActiveId
                              ? 'first-job-item'
                              : !setActiveId && index === 0
                                ? 'first-job-item'
                                : ''
                              }`}
                            variant="outlined"
                            sx={{ marginBottom: '1rem' }}
                            onClick={() => {
                              handleApplicationClick(application);
                              setSetActiveId(application?._id);
                            }}
                          >
                            <CardContent sx={{ paddingBottom: '0.7rem!important' }}>
                              <Box>{recommendedChip(application?.tj_score)}</Box>
                              <Box className="active-jobs-info">
                                <Grid container spacing={2}>
                                  <Grid item xs={7}>
                                    <Typography className="post-title">{application?.job_id?.title}</Typography>
                                    {!application?.job_id?.is_company_name_confidential &&
                                      <Typography className="company-name">{application?.job_id?.company}</Typography>}
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Box className="ctc-range">
                                      {application?.job_id?.is_compensation_confidential && <Box className="hide-my-info"></Box>}
                                      <Typography className="text-1">
                                        ₹{formatNumber(application?.job_id?.salary)}
                                      </Typography>
                                      <Typography className="text-2">{application?.job_id?.compensation}</Typography>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box mt={0.5} mb={1} className="work-type">
                                  <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                    <Typography variant="p">{application?.job_id?.location}</Typography>
                                    <Typography variant="body1">•</Typography>
                                    <Typography variant="p">{application?.job_id?.job_type}</Typography>
                                    <Typography variant="body1">•</Typography>
                                    <Typography variant="p">{application?.job_id?.work_mode}</Typography>
                                  </Stack>
                                </Box>

                                <Box mt={1}>
                                  <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="p" className="posted-date">
                                      {application?.job_id?.updatedAt
                                        ? getTimeDifferenceString(application?.job_id?.updatedAt, 'Posted')
                                        : 'N/A'}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        ))
                      )}
                    </Box>
                  </Box>
                </TabPanel>

                <TabPanel value="applied-jobs" sx={{ padding: 0 }}>
                  <Box className="active-jobs-list">
                    {/* Filter / Search Component */}
                    <FilterSearch fetchJobs={fetchData} activeTab={value} />

                    <Box className="active-jobs-container" sx={{ paddingBottom: '6rem!important' }} >

                      {showApplication?.length > 0 && (
                        showApplication?.map((application, index) => (
                          <Card
                            className={`job-card-view ${setActiveId && application?._id === setActiveId
                              ? 'first-job-item'
                              : !setActiveId && index === 0
                                ? 'first-job-item'
                                : ''
                              }`}
                            variant="outlined"
                            sx={{ marginBottom: '1rem' }}
                            onClick={() => {
                              handleApplicationClick(application);
                              setSetActiveId(application?._id);
                            }}
                          >
                            <CardContent>
                              <Box>
                                <Chip
                                  className={`job-${application.status === 'rejected' ? "rejected" : "applied"}`}
                                  label={changeNameFormat(application.status)}
                                  size="small"
                                />
                              </Box>
                              <Box className="active-jobs-info">
                                <Grid container spacing={2}>
                                  <Grid item xs={7}>
                                    <Typography className="post-title">{application?.job_id?.title}</Typography>
                                    {!application?.job_id?.is_company_name_confidential &&
                                      <Typography className="company-name">{application?.job_id?.company}</Typography>}
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Box className="ctc-range">
                                      {application?.job_id?.is_compensation_confidential && <Box className="hide-my-info"></Box>}
                                      <Typography className="text-1">
                                        ₹{formatNumber(application?.job_id?.salary)}
                                      </Typography>
                                      <Typography className="text-2">{application?.job_id?.compensation}</Typography>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box mt={0.5} mb={1} className="work-type">
                                  <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                    <Typography variant="p">{application?.job_id?.location}</Typography>
                                    <Typography variant="body1">•</Typography>
                                    <Typography variant="p">{application?.job_id?.job_type}</Typography>
                                    <Typography variant="body1">•</Typography>
                                    <Typography variant="p">{application?.job_id?.work_mode}</Typography>
                                  </Stack>
                                </Box>

                                <Typography variant="p" className="posted-date">
                                  {application?.job_id?.updatedAt
                                    ? getTimeDifferenceString(application?.job_id?.updatedAt, 'Posted')
                                    : 'N/A'}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        ))
                      )}
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
            }
          </TabContext>
        </Grid>
        {selectedApplication &&
          <Grid item xs={8}>
            {/* job full details */}
            {selectedApplication && (
              <JobFullDetails
                application={selectedApplication}
                onStatusChange={handleSelectedApplication}
                onApplied={handleAppliedJob}
                downloadResume={handleDownloadResume}
                showExternalJobStatus={showExternalJobStatus}
              />
            )}
          </Grid>
        }
        <Grid item xs={12}>
          {showEmptyState && <EmptyState tab={value} onChangeTab={handleTabToMyJob} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyJobs;
