import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  ToggleButton,
  ToggleButtonGro,
  AppBar,
  Toolbarup,
  Typography,
  Drawer,
  ToggleButtonGroup, Select, InputLabel, MenuItem,
  FormControl, Autocomplete, TextField,
  DialogContent
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getApplicationApplyDetails } from 'api/application';
import ChatBotDrawer from './ChatBotDrawer';
import JopApplicationDrawer from './JopApplicationDrawer';
import UpdateReleventSkillsDrawer from './UpdateReleventSkillsDrawer';
import AutoSavingJobAppDialog from './AutoSavingJobAppDialog';
import { useForm, Controller } from 'react-hook-form';
import { setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import dayjs from 'dayjs';
import ResumeFullView from '../basic-profile/ResumeFullView';
const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
import ExternalJob from './ExternalJob';
import ExternalJobStatus from './ExternalJobStatus';
import { getTimeDifferenceString, changeNameFormat, formatNumber } from 'utils/Utils';
import ExternalJobApplicationDialog from './ExternalJobApplicationDialog';
import UnsaveCard from './UnsaveCard';
import { useAuth } from 'hooks/useAuthContext';

const JobFullDetails = ({ application, onApplied, onStatusChange, downloadResume, showExternalJobStatus }) => {
  const { jobApply } = useAuth();
  const [id, setId] = useState(application?.job_id?._id || null);
  const { enqueueSnackbar } = useSnackbar();
  const [alignment, setAlignment] = useState('job-overview');
  const [candidateDetails, setCandidateDetails] = useState({});
  const [applicationData, setApplicationData] = useState({});
  const [resume, setResume] = useState({});
  const { control, handleSubmit } = useForm();
  const [dataSubmitted, setDataSubmitted] = useState(true);
  const jobFullOverviewRef = useRef(null);
  const jobOverviewRef = useRef(null);
  let headerRef = useRef(null);
  const jobDescRef = useRef(null);
  const jobApplicationRef = useRef(null);
  const [requirementMatchItem] = useState([
    'Offered Compensation',
    'Job Location',
    'Job Format',
    'Job Type',
    'Job Shift',
    'Notice Period',
  ]);

  const [isMatch, setIsMatch] = useState(false);
  const [matchingSkills, setMatchingSkills] = useState([]);
  const [viewResume, setViewResume] = useState(false);
  const [nonMatchingSkills, setNonMatchingSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("in-progress");
  const [industrySector, setIndustrySector] = useState('');

  const fetchData = async () => {
    try {
      const response = await getApplicationApplyDetails({ id });
      if (response?.success && response?.data != null) {
        setCandidateDetails(response?.data);
        if (application?.status !== 'saved') {
          setIsMatch(
            compareQualifications(application?.job_id?.qualifications || [], application?.requirements_matched?.qualifications || [])
          );
        } else {
          setIsMatch(
            compareQualifications(application?.job_id?.qualifications || [], response?.data?.qualification || [])
          );
        }
        setResume({
          summary: response?.data?.user?.candidate?.summary,
          educationData: response?.data?.educations,
          certificateData: response?.data?.certificate,
          workExperienceData: response?.data?.workHistory,
          customSection: response?.data?.user?.candidate?.custom_section,
          financeTechnologiesData: response?.data?.user?.candidate?.finance_technologies,
        });
        if (application?.status !== 'saved') {
          setIndustrySector(isIndustrySectorMatched(application?.requirements_matched?.work_history, application?.job_id?.industry_sector));
          setMatchingSkills(application?.requirements_matched?.matching_skills || []);
        } else {
          setIndustrySector(isIndustrySectorMatched(response?.data?.workHistory, application?.job_id?.industry_sector));
          setMatchingSkills(
            handleMatchingSkills(
              response?.data?.workHistory || [],
              response?.data?.certificate || [],
              response?.data?.educations || [],
              application?.job_id?.key_skills || []
            )
          );
        }
        if (showExternalJobStatus === 'showExternalStatus') {
          handleExternalJobStatus();
        }
      } else {
        console.error('Failed to fetch job info:', response?.data?.message);
      }
    } catch (error) {
      console.error('Error fetching job info:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAlignment('job-overview');
    setDataSubmitted(true);
    setLoading(true);
    setApplicationData(application)
    fetchData();
  }, [application]);

  const compareQualifications = (jobQualifications, candidateQualifications) => {
    if (!jobQualifications || !candidateQualifications) return false;
    const jobQualValues = jobQualifications.flatMap((qualification) =>
      qualification.values.map((value) => value.toLowerCase())
    );
    const candidateQualValues = candidateQualifications.map((qual) => qual.toLowerCase());
    const matchedQualifications = candidateQualValues.filter((qual) => jobQualValues?.includes(qual));
    return matchedQualifications.length > 0;
  };

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // update relevent skills drawer

  const [openSkillsDrawer, setOpenSkillsDrawer] = useState(false);

  const toggleSkillsDrawer = (newOpenSkills) => () => {
    setOpenSkillsDrawer(newOpenSkills);
  };

  // chat bot drawer

  const [openChatBotDrawer, setOpenChatBotDrawer] = useState(jobApply?.job?._id === application?._id ? jobApply?.openChatBot : false);

  const toggleChatBotDrawer = (newOpenSkills) => () => {
    setOpenChatBotDrawer(newOpenSkills);
  };

  // auto saving job app dialog

  const [autoSaveDialogOpen, setAutoSetDialogOpen] = useState(false);

  const autoSaveJobAppDialog = () => {
    setAutoSetDialogOpen(true);
  };

  const handleCloseAutoSaveDialog = () => {
    setAutoSetDialogOpen(false);
  };

  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      switch (newAlignment) {
        case 'job-overview':
          jobFullOverviewRef.current.scrollTo({
            top: jobOverviewRef.current.offsetTop,
            behavior: 'smooth',
          });
          break;
        case 'job-desc':
          jobFullOverviewRef.current.scrollTo({
            top: jobDescRef.current.offsetTop,
            behavior: 'smooth',
          });
          break;
        case 'job-application':
          jobFullOverviewRef.current.scrollTo({
            top: jobApplicationRef.current.offsetTop,
            behavior: 'smooth',
          });
          break;
        default:
          break;
      }
    }
  };


  const matchIcon = (job, candidate, title) => {
    const isMatchFunc = () => {
      if (title === 'Job Location') {
        const location = candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ');
        return candidate.includes('Any') || location?.includes(job);
      }
      if (job === candidate) return true;
      if (Array.isArray(candidate)) {
        return candidate.includes('Any') || candidate.includes(job);
      }
      if (!isNaN(job) && !isNaN(candidate)) {
        return Number(job) === Number(candidate);
      }
      return false;
    };
    if (isMatchFunc()) {
      return (
        <Box>
          <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <Box className="match-icon">
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Typography className="pref-match-title">{title || 'NA'}</Typography>
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box>
          <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <Box className="error-match-icon">
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <path
                    d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </Box>
            <Typography className="pref-match-title">{title || 'NA'}</Typography>
          </Stack>
        </Box>
      );
    }
  };

  const handleMatchingSkills = (workHistory, certificate, education, jobSkills) => {
    if (!workHistory || !Array.isArray(workHistory)) {
      console.error('Invalid work history provided.');
      return [];
    }
    if (!jobSkills || !Array.isArray(jobSkills)) {
      console.error('Invalid job skills provided.');
      return [];
    }
    let candidateSkills = [];
    workHistory.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills);
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    certificate.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills);
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    education.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills);
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    const uniqueCandidateSkills = [...new Set(candidateSkills)];
    const matchingSkills = [];
    const nonMatchingSkills = [];
    jobSkills.forEach((skill) => {
      if (uniqueCandidateSkills?.includes(skill)) {
        matchingSkills.push(skill);
      } else {
        nonMatchingSkills.push(skill);
      }
    });
    setNonMatchingSkills(nonMatchingSkills);
    return matchingSkills;
  };

  const handleUpdatePreference = async () => {
    await fetchData();
  };

  const handleSelectedResume = (resume) => {
    toggleChatBotDrawer(false);
    setResume(resume);
    handleRefetchJob();
  };

  const onSubmit = async (resume, answers) => {
    try {
      setDataSubmitted(false);
      const response = await setApplication({
        candidateDetails,
        matchingSkills,
        jobDetails: application?.job_id,
        status: 'applied',
        resumeData: resume,
        tj_score: application?.tj_score,
        answers: answers,
      });
      if (response?.success && response.data != null) {
        enqueueSnackbar('Successfully submitted the application.', { variant: 'success' });
        application.status = 'applied';
        application.answers = answers;
        application.applied_at = new Date();
        handleRefetchJob();
      } else {
        enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    } finally {
      setDataSubmitted(true);
    }
  };

  const handleCloseResume = () => {
    setViewResume(false);
  };

  const handleSkillsUpdate = (skills) => {
    fetchData();
  };

  const handleNonMatchingSkills = (skills) => {
    fetchData();
  };

  const recommendedChip = (score) => {
    if (score > 3) {
      return (
        <Chip
          className="chip-job-matching chip-suitable"
          avatar={<Avatar alt="Natacha" src={suitableIcon} />}
          label="Highly Suitable Job For You"
          size="small"
        />
      );
    } else if (score < 2) {
      return (
        <Chip
          className="chip-job-matching chip-not-matching"
          avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
          label="Job Not Matching Your Profile"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          className="chip-job-matching chip-mod-suitable"
          avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
          label="Moderately Suitable For You"
          size="small"
        />
      );
    }
  };

  const [openExternalJob, setOpenExternalJob] = useState(false);
  const handleApplyJob = () => {
    if (application?.job_id?.external_link) {
      setOpenExternalJob(true);
    } else {
      setOpenChatBotDrawer(true);
    }
  };
  const handleCloseExternalJobDialog = () => {
    setOpenExternalJob(false);
  };

  const [openExternalJobStatus, setOpenExternalJobStatus] = useState(false);
  const handleExternalJobStatus = () => {
    setOpenExternalJobStatus(true);
  };
  const handleCloseExternalJobStatusDialog = (msg) => {
    setOpenExternalJobStatus(false);
    // if (msg === 'applied') {
    onApplied(application);
    // }
  };
  const handleDownloadResume = () => {
    const dataForExternalJob = { job: application, candidateDetails: candidateDetails, matchingSkills: matchingSkills, tab: '3' };
    downloadResume(dataForExternalJob);
    handleCloseExternalJobDialog();
  };

  const handleTackStatusButton = () => {
    window.open(application?.job_id?.external_link);
  };

  const handleStatusChange = async (event) => {
    const status = event.target.value;
    const response = await setApplication({ status, jobId: application?.job_id?._id, isStatusChange: true })
    if (response?.success && response.data) {
      setStatus(response.data?.status);
      setApplicationData(response?.data)
      onStatusChange(response?.data)
      enqueueSnackbar('Successfully update the application status.', { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to update the application status. Please try again.', { variant: 'error' });
    }
  }

  const [extJobAppDialogOpen, setExpJobAppDialogOpen] = useState(false);

  const hadleOpenExtJobAppDialog = () => {
    setExpJobAppDialogOpen(true);
  };

  const handleCloseExtJopAppDialog = () => {
    setExpJobAppDialogOpen(false);
  };

  const handleRefetchJob = () => {
    onApplied();
  };

  // unsave job
  const [autoUnsaveDialogOpen, setAutoUnsaveDialogOpen] = useState(false);
  const handleOpenAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(true);
    // handleUnsaveJob();
  };
  const handleCloseAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(false);
  };

  const handleUnsaveJob = async () => {
    try {
      const response = await setApplication({ jobId: application?.job_id?._id, type: 'delete' });
      if (response?.success) {
        application.status = 'unsaved';
        handleRefetchJob();
        handleCloseAutoUnsaveDialog();
      } else {
        enqueueSnackbar('Failed to Unsave the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error unsaving for later:', error);
    }
  };

  const isIndustrySectorMatched = (workHistory, targetIndustrySector) => {
    return workHistory?.some(
      (work) => work?.organization_details?.industry_sector === targetIndustrySector
    ) || false;
  };

  return (
    <Box className="job-full-details">
      <Box className="job-full-details-header">
        <Box className="header-top">
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography className="title">{application?.job_id?.title || 'N/A'}</Typography>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {!application?.job_id?.is_company_name_confidential &&
                  <><Typography className="company-name">{application?.job_id?.company || 'N/A'}</Typography>
                    <Divider orientation="vertical" variant="middle" flexItem /></>}
                {/* <Box className="ratings">
                  <Stack direction="row" alignItems={'center'} spacing={0.5}>
                    <SvgIcon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                        <path
                          d="M5.71663 0.815217C5.81002 0.546549 6.18998 0.546548 6.28337 0.815216L7.34214 3.86117C7.38334 3.97969 7.49395 4.06006 7.6194 4.06261L10.8435 4.12831C11.1278 4.13411 11.2452 4.49547 11.0186 4.66731L8.44889 6.61552C8.3489 6.69133 8.30665 6.82135 8.34299 6.94146L9.27679 10.028C9.35916 10.3003 9.05176 10.5236 8.81829 10.3611L6.17135 8.51924C6.06836 8.44757 5.93164 8.44757 5.82865 8.51924L3.18171 10.3611C2.94824 10.5236 2.64084 10.3003 2.72321 10.028L3.65701 6.94146C3.69335 6.82135 3.6511 6.69133 3.55111 6.61552L0.981412 4.66731C0.754753 4.49547 0.872166 4.13411 1.15654 4.12831L4.3806 4.06261C4.50605 4.06006 4.61666 3.97969 4.65786 3.86117L5.71663 0.815217Z"
                          fill="#29C138"
                        />
                      </svg>
                    </SvgIcon>

                    <Typography className="rating-count">{application?.tj_score || 'N/A'}</Typography>
                  </Stack>
                </Box> */}

                <Typography className="city-name">{application?.job_id?.location || 'N/A'}</Typography>
              </Stack>
            </Box>

            {loading ? (
              <Stack direction="row" spacing={3}>
                <Skeleton height={40} width="100%" />
              </Stack>
            ) : (
              <Stack direction="row" spacing={3}>
                {application?.status === 'saved' && (
                  <Button sx={{ width: '150px' }}
                    variant="outlined"
                    size="large"
                    className="saved-btn"
                    onClick={handleOpenAutoUnsaveDialog}
                    startIcon={
                      <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                            fill="white"
                          />
                        </svg>
                      </SvgIcon>
                    }
                  >
                    Job Saved
                  </Button>
                )}
                {application?.status === 'saved' ? (
                  <Button sx={{ width: '150px' }}
                    variant="contained"
                    size="large"
                    className={application?.is_external_job_application ? "external-apply-now-btn" : "apply-now-btn"}
                    endIcon={application?.is_external_job_application ? < SvgIcon >
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                      </svg>
                    </SvgIcon> : null}
                    onClick={handleApplyJob}
                  >
                    Apply Now
                  </Button>
                ) : (
                  application?.is_external_job_application &&
                  <Button sx={{ width: '150px' }} variant="outlined" size="large" className="external-apply-now-btn" endIcon={<SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                    </svg>
                  </SvgIcon>}
                    onClick={handleTackStatusButton}
                  >Track Status </Button>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
        {loading ? (
          <Box className="header-bottom">
            <Skeleton height={40} width="40%" />
          </Box>
        ) : (
          application?.is_external_job_application && application?.status !== 'saved' && (
            <Box className="external-job-app">
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                <Box>
                  <Typography className="ext-title">External Job Application</Typography>
                  <Typography className="ext-info-text">You can update application status using the dropdown on right hand side</Typography>
                </Box>
                <Box>
                  <Button variant="outlined" className="job-applied-btn" endIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path d="M13.7802 5.9668L9.43355 10.3135C8.92021 10.8268 8.08021 10.8268 7.56688 10.3135L3.22021 5.9668" stroke="#1575C6" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  }
                    onClick={hadleOpenExtJobAppDialog}>
                    {changeNameFormat(applicationData?.status)}
                  </Button>
                  <Typography className="updated-date" mt={0.5}>
                    {applicationData?.applied_at && getTimeDifferenceString(applicationData?.applied_at, changeNameFormat(applicationData?.status))}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          )
        )}

        {loading ? (
          <Box className="header-bottom">
            <Skeleton height={40} width="40%" />
          </Box>
        ) : (
          <Box className="header-bottom my-internal-jobs">
            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
              <ToggleButtonGroup
                className="job-desc-toggle-btn"
                color="primary"
                value={alignment}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform"
              >
                <ToggleButton value="job-overview">Job Overview</ToggleButton>
                <ToggleButton value="job-desc">Job Responsibilities</ToggleButton>
                {(application?.answers || application?.resume_data) && application?.status === 'applied' && (
                  <ToggleButton value="job-application">Job Application</ToggleButton>
                )}
              </ToggleButtonGroup>
              {!applicationData?.is_external_job_application && (
                <Box>
                  <Chip
                    className={`chip-job-status job-${applicationData?.status || application.status}  `}
                    label={changeNameFormat(applicationData?.status || application.status)}
                    size="small"
                  />
                  <Typography className="updated-date">{applicationData?.status !== "saved" ? (applicationData?.applied_at && getTimeDifferenceString(applicationData?.applied_at, changeNameFormat(applicationData?.status))) : getTimeDifferenceString(applicationData?.updatedAt, "Updated")}</Typography>
                </Box>
              )}
            </Stack>
          </Box>
        )
        }
      </Box >

      {/* job overview block */}
      {
        loading ? (
          <Box className="job-full-overview">
            <Box className="job-overview-wrapper" mb={4}>
              <Card variant="outlined" className="requirements-match-card" sx={{ marginBottom: 3 }}>
                <CardContent>
                  <Skeleton height={60} />
                  <Skeleton height={40} width="60%" />
                </CardContent>
              </Card>
            </Box>
          </Box>
        ) : (
          <Box ref={jobFullOverviewRef} className="job-full-overview">
            <Box ref={jobOverviewRef} id="job-overview">
              <Box className="job-overview-wrapper" mb={4}>
                <Typography className="title">Job Overview</Typography>
                <Typography className="about-post-info" style={{ textAlign: 'justify', marginBottom: 15 }}>
                  {application?.job_id?.company_overview}
                </Typography>
                <Typography className="about-post-info" style={{ textAlign: 'justify' }}>
                  {application?.job_id?.job_overview}
                </Typography>

                {application?.job_id?.overviews?.length > 0 &&
                  application?.job_id?.overviews?.map((item, index) => (
                    <List
                      className="job-overview-list"
                      sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}
                    >
                      <ListItem sx={{ display: 'list-item' }} disablePadding key={application?._id}>
                        {item}
                      </ListItem>
                    </List>
                  ))}
              </Box>

              <Card variant="outlined" className="requirements-match-card" sx={{ marginBottom: 3 }}>
                <CardContent>
                  {application?.status === 'saved' && recommendedChip(application?.tj_score)}
                  <Typography className="title">Requirements Match</Typography>
                  <Typography className="info-text">
                    Matched against your educational background & work experience
                  </Typography>
                  <Box className="requirements-match" mt={1.5}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" align spacing={0.5}>
                            {isMatch ? (
                              <Stack>
                                <Box className="match-icon">
                                  <SvgIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="8"
                                      height="6"
                                      viewBox="0 0 8 6"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </SvgIcon>
                                </Box>
                              </Stack>
                            ) : (
                              <Stack>
                                <Box className="error-match-icon">
                                  <SvgIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="6"
                                      height="6"
                                      viewBox="0 0 6 6"
                                      fill="none"
                                    >
                                      <path
                                        d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </SvgIcon>
                                </Box>
                              </Stack>
                            )}

                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Education</Typography>
                              <Typography className="req-text">
                                {application?.requirements?.qualifications?.map((qualification, index) => (
                                  qualification?.values?.join(qualification.option ? ' , ' : ' | ') || 'N/A'))?.join(' | ')
                                }
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {application?.candidate_score?.experience_level > 2 ? (
                              <Box className="match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            ) : (
                              <Box className="error-match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            )}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Work Experience</Typography>
                              <Typography className="req-text">
                                {application?.requirements?.experience || 'N/A'}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {matchingSkills?.length > application?.job_id?.key_skills?.length / 2 ? (
                              <Box className="match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            ) : (
                              <Box className="error-match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            )}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Skills</Typography>
                              <Typography className="req-text">
                                {matchingSkills?.length || 0} /
                                {application?.requirements?.matching_skills?.length || 0} present
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {industrySector ? (
                              <Box className="match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            ) : (
                              <Box className="error-match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            )}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Industry Sector</Typography>
                              <Typography className="req-text">{application?.job_id?.industry_sector || 'N/A'}</Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                  <Box className="preferences-match">
                    <Typography className="title">Preferences Match</Typography>
                    <Typography className="pref-info-text" component="p">
                      Matched against your defined job preferences,{' '}
                      {application?.status === 'saved' && (
                        <Link href="#" underline="none" onClick={toggleDrawer(true)}>click here to update them</Link>
                      )}
                    </Typography>
                    <Box mt={2}>
                      <Stack spacing={1.5}>
                        {!application?.job_id?.is_compensation_confidential && (
                          <Grid container>
                            <Grid item xs={3}>
                              {matchIcon(
                                application?.job_id?.salary,
                                application?.status === 'saved'
                                  ? candidateDetails?.user?.candidate?.job_preferences?.expected_ctc
                                  : application?.requirements_matched?.salary || [],
                                requirementMatchItem[0]
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              <Box>
                                <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                                  <Typography className="pref-match-info">
                                    ₹{formatNumber(application?.job_id?.salary) || 'NA'}
                                    {application?.job_id?.compensation && `(${application?.job_id?.compensation})`}
                                  </Typography>
                                  {application?.status === 'saved' && Number(application?.job_id?.salary) !== Number(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc) && (
                                    <Typography className="pref-match-info notice-period-error">
                                      You've mentioned ₹{formatNumber(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc)}
                                    </Typography>
                                  )}
                                </Stack>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          <Grid item xs={3}>
                            {matchIcon(
                              application?.job_id?.location,
                              application?.status === 'saved'
                                ? candidateDetails?.user?.candidate?.job_preferences?.prefered_location
                                : application?.requirements_matched?.location || [],
                              requirementMatchItem[1]
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                                <Typography className="pref-match-info">
                                  {application?.job_id?.location || 'NA'}
                                </Typography>
                                {application?.status === 'saved' &&
                                  candidateDetails?.user?.candidate?.job_preferences?.prefered_location &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ').includes('Any') &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ').includes(application?.job_id?.location) && (
                                    <Typography className="pref-match-info notice-period-error">
                                      (You've mentioned a{' '}
                                      {candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ')})
                                    </Typography>
                                  )}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3}>
                            {matchIcon(
                              application?.job_id?.work_mode,
                              application?.status === 'saved'
                                ? candidateDetails?.user?.candidate?.job_preferences?.work_format
                                : application?.requirements_matched?.job_format || [],
                              requirementMatchItem[2]
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography className="pref-match-info">
                                  {application?.job_id?.work_mode || 'NA'}
                                </Typography>
                                {application?.status === 'saved' &&
                                  candidateDetails?.user?.candidate?.job_preferences?.work_format &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.work_format.includes('Any') &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.work_format.includes(application?.job_id?.work_mode) && (
                                    <Typography className="pref-match-info notice-period-error">
                                      (You've mentioned a{' '}
                                      {candidateDetails?.user?.candidate?.job_preferences?.work_format?.join(', ')})
                                    </Typography>
                                  )}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3}>
                            {matchIcon(
                              application?.job_id?.job_type,
                              application?.status === 'saved'
                                ? candidateDetails?.user?.candidate?.job_preferences?.job_type
                                : application?.requirements_matched?.job_type,
                              requirementMatchItem[3]
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography className="pref-match-info">
                                  {application?.job_id?.job_type || 'NA'}
                                </Typography>
                                {application?.status === 'saved' &&
                                  (candidateDetails?.user?.candidate?.job_preferences?.job_type?.includes(
                                    application?.job_id?.job_type
                                  ) ? ('') : (
                                    <Typography className="pref-match-info notice-period-error">
                                      (You've mentioned a{' '}
                                      {candidateDetails?.user?.candidate?.job_preferences?.job_type?.join(', ')})
                                    </Typography>
                                  ))}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3}>
                            {matchIcon(
                              application?.job_id?.job_shift,
                              application?.status === 'saved'
                                ? candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts
                                : application?.requirements_matched?.job_shift,
                              requirementMatchItem[4]
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography className="pref-match-info">
                                  {application?.job_id?.job_shift ? `${application?.job_id?.job_shift}` : 'NA'}
                                </Typography>
                                {application?.status === 'saved' &&
                                  candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts.includes('Any') &&
                                  !candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts.includes(application?.job_id?.job_shift) && (
                                    <Typography className="pref-match-info notice-period-error">
                                      (You've mentioned a{' '}
                                      {candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts?.join(', ')}
                                      )
                                    </Typography>
                                  )}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3}>
                            {matchIcon(
                              application?.job_id?.notice_period,
                              application?.status === 'saved'
                                ? `${candidateDetails?.user?.candidate?.job_preferences?.notice_period}`
                                : `${application?.requirements_matched?.notice_period}`,
                              requirementMatchItem[5]
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <Box>
                              <Stack direction="row" alignItems={'flex-start'} spacing={0.7}>
                                <Typography className="pref-match-info">{application?.job_id?.notice_period ? `${application?.job_id?.notice_period} days` : 'NA'}
                                </Typography>
                                {application?.status === 'saved' &&
                                  application?.job_id?.notice_period &&
                                  candidateDetails?.user?.candidate?.job_preferences?.notice_period &&
                                  application?.job_id?.notice_period !== candidateDetails?.user?.candidate?.job_preferences?.notice_period && (
                                    <Typography className="pref-match-info notice-period-error">
                                      (You've mentioned a {candidateDetails?.user?.candidate?.job_preferences?.notice_period}-day notice)
                                    </Typography>
                                  )}
                              </Stack>
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              <Card variant="outlined" className="skills-match-card" sx={{ marginBottom: 3 }}>
                <CardContent>
                  <Box>
                    <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                      <Box>
                        <Typography className="title">Skills Match Analysis</Typography>
                      </Box>
                      <Box>
                        {application?.status === 'saved' && (
                          <Link
                            href="#"
                            underline="none"
                            className="update-skills-link"
                            onClick={toggleSkillsDrawer(true)}
                          >
                            Update relevant skills
                          </Link>
                        )}
                      </Box>
                    </Stack>
                    <Typography className="info-text">
                      You posses {matchingSkills?.length || 0} present out{' '}
                      {application?.requirements?.matching_skills?.length || 0} required skills (You skills can be updated
                      within the 'My Profile' section)
                    </Typography>
                  </Box>
                  <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                  <Stack direction="row" flexWrap="wrap">
                    {application?.job_id?.key_skills
                      ? application?.job_id?.key_skills.map((skill, index) => (
                        <Chip
                          key={index}
                          icon={
                            matchingSkills?.includes(skill) ? (
                              <Box className="match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            ) : (
                              <Box className="error-match-icon">
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                      fill="white"
                                    />
                                  </svg>
                                </SvgIcon>
                              </Box>
                            )
                          }
                          label={skill}
                          variant="outlined"
                          disabled={!matchingSkills?.includes(skill)}
                        />
                      ))
                      : 'No Skills'}
                  </Stack>
                </CardContent>
              </Card>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
            {/* description */}
            <Box ref={jobDescRef} id="job-desc" className="job-description">
              <Typography className="title">Job Responsibilities</Typography>
              <Typography className="info-text" style={{ textAlign: 'justify' }}>
                {application?.job_id?.description}
              </Typography>
            </Box>
            {/* questions */}
            {(application?.answers || application?.resume_data) && application?.status !== 'saved' && (
              <Box>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                <Box ref={jobApplicationRef} id="job-application" className="job-application">
                  <Typography className="title">Job Application</Typography>
                  {application?.job_id?.external_link ? (
                    application?.job_id?.question && application?.job_id?.question.length > 0 && (
                      <>
                        <Typography className="info-text">
                          Your response to the screening questions & resume you applied with
                        </Typography>

                        <List
                          className="job-application-list"
                          sx={{ marginTop: '1rem', paddingTop: 0, paddingLeft: '1.5rem', listStyle: 'decimal' }}
                        >
                          {application?.job_id?.question.map((qus, index) => (
                            <ListItem sx={{ display: 'list-item' }} disablePadding key={index}>
                              <Typography className="questions-text">{qus}</Typography>
                              {/* <Typography className="answer-text">{value}</Typography> */}
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )
                  ) : (
                    application?.answers && Object.keys(application?.answers).length > 0 && (
                      <>
                        <Typography className="info-text">
                          Your response to the screening questions & resume you applied with
                        </Typography>

                        <List
                          className="job-application-list"
                          sx={{ marginTop: '1rem', paddingTop: 0, paddingLeft: '1.5rem', listStyle: 'decimal' }}
                        >
                          {Object.entries(application?.answers).map(([key, value]) => (
                            <ListItem sx={{ display: 'list-item' }} disablePadding key={key}>
                              <Typography className="questions-text">{key}</Typography>
                              <Typography className="answer-text">{value}</Typography>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )
                  )}
                </Box>
              </Box>
            )}
            {!(application?.job_id?.external_link) && (application?.status !== 'saved' && application?.status !== 'Not Applied') && (
              <Box className="applied-resume-date-info" >
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                  <Typography className="info-text">
                    You applied with {application?.resume_data?.title} for this job on{' '}
                    {dayjs(application?.applied_at).format('DD MMM, YYYY')}
                  </Typography>
                  <Link onClick={() => setViewResume(true)}>View Resume</Link>
                </Stack>
              </Box>
            )}
            <Box sx={{ marginTop: '20rem' }}>
              {'   '}
            </Box>
          </Box>
        )}

      {/* apply now chatboat drawer */}
      <Drawer
        open={openChatBotDrawer}
        className="chat-bot-drawer-dialog"
        anchor={'right'}
        onClose={toggleChatBotDrawer(false)}
      >
        <ChatBotDrawer
          questions={application?.job_id?.question}
          onClose={toggleChatBotDrawer(false)}
          onSelected={handleSelectedResume}
          tab={'3'}
          data={application}
          candidateDetails={candidateDetails}
          matchingSkills={matchingSkills}
        />
      </Drawer>

      {/* update job preferences drawer */}
      <Drawer open={open} anchor={'right'} onClose={toggleDrawer(false)}>
        <JopApplicationDrawer
          onClose={toggleDrawer(false)}
          data={candidateDetails?.user?.candidate?.job_preferences}
          onUpdatePreference={handleUpdatePreference}
        />
      </Drawer>

      {/* update relevent skills drawer */}
      <Drawer open={openSkillsDrawer} anchor={'right'} onClose={toggleSkillsDrawer(false)}>
        <UpdateReleventSkillsDrawer
          onClose={toggleSkillsDrawer(false)}
          workExperience={candidateDetails?.workHistory}
          education={candidateDetails?.educations}
          certificate={candidateDetails?.certificate}
          skillsUpdate={handleSkillsUpdate}
          nonMatchingSkills={nonMatchingSkills}
          matchingSkills={matchingSkills}
          setNonMatchingSkills={handleNonMatchingSkills}
        />
      </Drawer>

      {/* auto save job app dialog */}
      <Dialog
        open={autoSaveDialogOpen}
        onClose={handleCloseAutoSaveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="auto-save-app-dialog"
      >
        <AutoSavingJobAppDialog />
      </Dialog>
      {/* resume preview */}
      <Dialog
        open={viewResume}
        onClose={handleCloseResume}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="full-view-resume-dialog"
      >
        <ResumeFullView
          summary={application?.resume_data ? application?.resume_data?.summary : resume?.summary}
          educationData={application?.resume_data ? application?.resume_data?.educations : resume?.educations}
          certificateData={application?.resume_data ? application?.resume_data?.certificates : resume?.certificates}
          workExperienceData={application?.resume_data ? application?.resume_data?.work_history : resume?.work_history}
          customSection={application?.resume_data ? application?.resume_data?.customSection : resume?.customSection}
          financeTechologiesData={
            application?.resume_data ? application?.resume_data?.financeTechologiesData : resume?.financeTechologiesData
          }
        />
      </Dialog>
      {/* external job and download resume dialog */}
      <Dialog
        open={openExternalJob}
        onClose={handleCloseExternalJobDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <ExternalJob onClose={handleCloseExternalJobDialog} externalJobStatus={handleExternalJobStatus} downloadResume={handleDownloadResume} job={application} tab={'3'} candidateDetails={candidateDetails} matchingSkills={matchingSkills} />
        </DialogContent>
      </Dialog>
      {/* external job status */}
      <Dialog
        open={openExternalJobStatus}
        onClose={handleCloseExternalJobStatusDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <ExternalJobStatus onClose={handleCloseExternalJobStatusDialog} job={application} tab={'3'} candidateDetails={candidateDetails} matchingSkills={matchingSkills} />
        </DialogContent>
      </Dialog>
      {/* External job application dialog */}
      <Dialog
        open={extJobAppDialogOpen}
        onClose={handleCloseExtJopAppDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="external-job-app-dialog"
      >
        <ExternalJobApplicationDialog onClose={handleCloseExtJopAppDialog} externalJobStatus={handleExternalJobStatus} downloadResume={handleDownloadResume} jobId={application?.job_id?._id} jobDetails={application?.job_id} tj_score={application?.tj_score} handleRefetchJob={handleRefetchJob} />
      </Dialog>
      {/* unsaved job */}
      <Dialog
        open={autoUnsaveDialogOpen}
        onClose={handleCloseAutoUnsaveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <UnsaveCard onClose={handleCloseAutoUnsaveDialog} onUnsave={handleUnsaveJob} title={application?.job_id?.title} />
        </DialogContent>
      </Dialog>
    </Box >
  );
};

export default JobFullDetails;
