import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Link,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  Divider,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PhoneInput from 'components/country-code-picker';
import { updateContact } from 'api/users';
import OneTimePassword from '../login/OneTimePassword';
import { useSnackbar } from 'components/snackbar';
import { useAuth, } from 'hooks/useAuthContext';

const UpdateWhatsapp = ({ onCancel, onCancelWithNumber }) => {
  const { user, updateContext } = useAuth();

  // show hide flag
  const [showFlag, setShowFlag] = React.useState(user?.phone ? true : false);
  const [phoneNumber, setPhoneNumber] = React.useState(user?.phone || '');
  const [showOtpInput, setShowOtpInput] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleRemove = () => {
    setIsRemoving(true);
    handleSubmit();
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };
  const handleClose = (message) => {
    if (message === 'successfully') {
      onCancelWithNumber(phoneNumber);
    }
    setOpen(false);
  };

  const handleFlag = () => {
    setShowFlag(true);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length === 10) {
      setShowFlag(true);
      setError('');
    } else {
      // setShowFlag(false);
      setError('Phone number must be 10 digits.');
    }
    setPhoneNumber(value);
  };

  const handleSubmit = async () => {
    if (phoneNumber.length !== 10) {
      setError('Phone number must be 10 digits.');
      setIsRemoving(false);
      return;
    }
    if (!open) {
      const response = await updateContact({ phoneNumber });
      if (response?.success === true && response.data?.id) {
        setUserId(response.data.id);
        enqueueSnackbar(`OTP sent.`, { variant: 'success' });
        handleOpenOtpDialog();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    setIsRemoving(false);
  };

  const handleOnBlurFlag = () => {
    if (!phoneNumber) {
      setShowFlag(false);
    }
  }

  return (
    <Box>
      <Card className="otp-card-wrapper" variant="outlined">
        <CardContent>
          {user?.phone &&
            <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onCancel}>
              <SvgIcon sx={{ width: '20px', height: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_456_20993)">
                    <path
                      d="M7 7L17 17M17 7L7 17L17 7Z"
                      stroke="#111827"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_456_20993">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </SvgIcon>
            </IconButton>
          }

          <Box mb={2.5} sx={{ textAlign: 'left' }}>
            <Typography mb={1} className="title" component="h6">
              Update WhatsApp Number
            </Typography>
            <Typography sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
              This number would be used for primary communication by your recruiters
            </Typography>
          </Box>

          <Box mb={4} >
            <FormControl
              fullWidth
              className="wh-number-input"
              size="small"
              onClick={handleFlag}
              onChange={handleChange}
              onBlur={handleOnBlurFlag}
              onFocus={handleFlag}
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'relative',
                minWidth: '0px',
                padding: '0px',
                margin: '0px',
                border: '0px',
                verticalAlign: 'top',
                width: '100%',
                height: '60px',
              }}
            >
              {showFlag && (
                <Box className="iti-flag-container" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} mb={3}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <Box className="iti-flag"></Box>
                    <Box class="selected-dial-code">+91</Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </Stack>
                </Box>
              )}
              <TextField
                size="small"
                id="phone_number"
                name="phone_number"
                label="WhatsApp Number"
                type="number"
                // {...register('phone_number')}
                // error={!!errors.phone_number}
                // helperText={errors.phone_number?.message}
                onChange={handleChange}
                value={phoneNumber}
                error={!!error}
                helperText={error}
                InputLabelProps={{
                  shrink: !!phoneNumber || showFlag,
                }}
              />
              {/* {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )} */}

            </FormControl>
          </Box>

          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onCancel}>
              {user?.phone ? 'Cancel' : 'Log out' }
            </Button>
            <Button
              fullWidth
              className="otp-btn"
              variant="contained"
              size="medium"
              onClick={handleRemove}
              disabled={isRemoving}
            >
              {isRemoving ? 'Sending...' : 'Send OTP'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <OneTimePassword userId={userId} onCancelOTP={handleClose} phoneNumber={phoneNumber} />
      </Dialog>
    </Box>
  );
};

export default UpdateWhatsapp;
