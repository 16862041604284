import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import dayjs from 'dayjs';
import { updateCandidateWorkExperienceGap } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { useNavigate } from 'react-router-dom';

const CareerBreaksIdentifiedDialog = ({ gap, onClose, onWorkExperienceDataChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);

  const methods = useForm({
    defaultValues: {
      gap_title: "",
      reasons: "",
      showInResume: false,
    },
  });

  const { handleSubmit, control, formState: { errors } } = methods;

  const onSubmit = (data) => {
    setIsUpdating(true);
    const { gap_title, reasons, showInResume } = data;
    const formattedData = reasons.map((reason, index) => ({
      gap_explaination: reason,
      gap_title: gap_title[index],
      show_in_resume: showInResume[index],
      work_history_from: gap[index].startDate,
      work_history_to: gap[index].endDate,
      organization_name: 'Work Gap',
      highest_designation: `Career Break ${index + 1}`
    }));
    addGap(formattedData);
  };

  const addGap = async (formattedData) => {
    try {
      const response = await updateCandidateWorkExperienceGap(formattedData);
      if (response) {
        enqueueSnackbar(response.message, { variant: 'success' });
        onClose();
        onWorkExperienceDataChange();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const wordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box mb={3}>
          <Typography mb={1} className="main-title">
            {gap.length} {gap.length > 1 ? 'breaks ' : 'break '} identified during your work experience
          </Typography>
          <Typography className="main-info">
            Please provide the reasons for these {gap.length > 1 ? 'breaks' : 'break'}. Companies & recruiters need this information to understand you better.
          </Typography>
        </Box>

        <FormProvider {...methods}>
          <Box className="career-breaks-container">
            {gap.map((item, index) => (
              <Box key={index} >
                <Stack spacing={1} className="break-row">
                  <Typography className="title">Break {index + 1}</Typography>
                  <Typography className="break-gap-text">
                    {dayjs(item.startDate).format('MMM YYYY')} - {dayjs(item.endDate).format('MMM YYYY')}
                  </Typography>
                  <FormControl fullWidth sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    position: 'relative',
                    minWidth: '0px',
                    padding: '0px',
                    margin: '0px',
                    border: '0px',
                    verticalAlign: 'top',
                    width: '100%',
                    height: '60px',
                  }}>
                    <Controller
                      name={`gap_title[${index}]`}
                      control={control}
                      rules={{
                        required: 'Please enter the title for the gap'
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                           size="small"
                          placeholder="Title"
                          label="Title"
                          variant="outlined"
                          error={!!errors.gap_title && !!errors.gap_title[index]}
                          helperText={errors.gap_title && errors.gap_title[index] ? errors.gap_title[index].message : ''}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      name={`reasons[${index}]`}
                      control={control}
                      rules={{
                        required: 'Please enter the reason for the gap',
                        validate: {
                          maxWords: (value) => wordCount(value) <= 25 || 'Reason cannot exceed 25 words',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          placeholder="Reason within 25 words"
                          label="Reason"
                          variant="outlined"
                          error={!!errors.reasons && !!errors.reasons[index]}
                          helperText={errors.reasons && errors.reasons[index] ? errors.reasons[index].message : ''}
                          multiline
                        />
                      )}
                    />
                  </FormControl>
                  <FormControlLabel sx={{fontSize : '14px'}}
                    control={
                      <Checkbox
                        {...methods.register(`showInResume[${index}]`)}
                        defaultChecked={false}
                      />
                    }
                    label="Show this break in my resume"
                  />
                </Stack>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

              </Box>
            ))}
          </Box>
          <Box className="career-breaks-footer">
            <Button
              className="cbi-update-btn"
              variant="contained"
              size="medium"
              onClick={handleSubmit(onSubmit)}
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update and Review Resume'}
            </Button>
          </Box>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default CareerBreaksIdentifiedDialog;
