import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import { useAuth } from 'hooks/useAuthContext';

// Pages
import AllReports from './pages/temp/AllReports';
// import LoginPage from './pages/temp/LoginPage';
import Page404 from './pages/temp/Page404';
import Profile from 'pages/temp/Profile';

import JobList from 'pages/job/JobList';
import JobDescription from 'pages/job/JobDescription';
import NewJob from 'pages/job/NewJob';

import BasicProfile from 'pages/candidate/BasicProfile';
// import WorkAndSummary from 'pages/candidate/WorkAndSummary';
// import AddWorkHistoryForm from 'pages/candidate/AddWorkHistoryForm';
// import AddEducationForm from 'pages/candidate/AddEducationForm';
// import Resume from 'pages/candidate/AddResume';
import CreateUpdateResume from 'components/job-application/CreateUpdateResume';
// import JobRelatedPreferences from 'pages/candidate/JobRelatedPreferences';
// import ExpetiesAndSkills from 'pages/candidate/ExpertiesAndSkills'

import JobPreferences from 'components/job-preferences/JobPreferences';

import Registration from 'pages/auth/Registration';
import LoginPage from 'pages/auth/LoginPage';
import PasswordReset from 'pages/auth/PasswordReset';

import ForgotPassword from 'components/login/ForgotPassword';
import EnterOTP from 'components/login/EnterOTP';
import RedirectPage from 'components/login/Redirect';

import ResetPassword from 'components/login/ResetPassword';

import CompanyList from 'pages/company/CompanyList';
import CompanyMaster from 'pages/company/CompanyMaster';
import CompanyStaffForm from 'pages/company/CompanyStaffForm';
import ManageUser from 'pages/company/ManageUser';

import JobsApplyApplication from 'pages/application/JobsApplyApplication';
import ApplicationList from 'pages/application/ApplicationList';
import JobAndApplicationDetails from 'pages/application/JobAndApplicationDetails';

import JobApplicants from 'pages/admin/JobApplicants';
import CandidateApplications from 'pages/admin/CandidateApplications';
import CandidatesList from 'pages/admin/CandidatesList';
import UserManagement from 'pages/admin/UserManagement';

// feedback
import FeedbackList from 'pages/feedback/FeedbackList';

import { SplashScreen } from 'components/loading-screen';

import Register from 'components/login/Register';
import ChooseResumeTemplate from 'components/choose-resume-template/ChooseResumeTemplate';
import JobApplication from 'components/job-application/JobApplication';
import NewJobPosting from 'components/admin/newJobPosting';


// ----------------------------------------------------------------------
export default function Router({ }) {
  const { loading, user } = useAuth();
  if (loading) return <SplashScreen />;
  let children = [];
  switch (user?.user_type) {
    case 'user':
      children = [
        { path: 'home', element:  (user?.stages === 1 || user?.stages === 2) ? <BasicProfile/> : (user?.stages === 3) ? < JobPreferences /> : (user?.stages === 4)? <JobApplication /> : <BasicProfile/> },
        { path: 'job-application', element: <JobApplication /> },
        { path: 'resume', element: <ChooseResumeTemplate /> },
        { path: 'job-preferences', element: < JobPreferences /> },
        // { path: 'applications', element: <ApplicationList /> },
        { path: 'profile', element: <BasicProfile /> },
        // { path: 'work-and-summary', element: <WorkAndSummary /> },
        // { path: 'settings', element: <PasswordReset /> },
        // { path: 'jobs/:id', element: <JobDescription /> },
        // { path: 'review-details/:id', element: <JobsApplyApplication /> },
        // { path: 'add-work-history-form', element: <AddWorkHistoryForm /> },
        // { path: 'add-education-form', element: <AddEducationForm /> },
        // { path: 'job-application-description/:id', element: <JobAndApplicationDetails /> },
        // { path: 'add-resume', element: <Resume /> },
        // { path: 'job-related-preferences', element: <JobRelatedPreferences /> },
        // { path: 'experties-and-skills', element: <ExpetiesAndSkills /> },
        // { path: 'feedback', element: <FeedbackList /> },
        // { path: 'job', element: <JobList /> },
        { path: 'create-update-resume', element: <CreateUpdateResume /> },
      ];
      break;

    case 'company':
      children = [
        { path: 'home', element: <JobList /> },
        { path: 'candidates', element: <CandidatesList /> },
        { path: 'candidate-applications/:id', element: <CandidateApplications /> },
        { path: 'profile', element: <Profile /> },
        { path: 'company-profile', element: <CompanyMaster /> },
        { path: 'reset-password', element: <PasswordReset /> },
        { path: 'job-applicants/:id', element: <JobApplicants /> },
        { path: 'job-form', element: <NewJobPosting /> },
        { path: 'company-details/:id', element: <CompanyMaster /> },
        { path: 'add-staff/:id', element: <CompanyStaffForm /> },
        { path: 'staff-details/:id', element: <CompanyStaffForm /> },
        { path: 'manage-user', element: <ManageUser /> },
        { path: 'feedback', element: <FeedbackList /> },
      ];
      break;

    case 'companyadmin':
      children = [
        { path: 'home', element: <JobList /> },
        { path: 'candidates', element: <CandidatesList /> },
        { path: 'candidate-applications/:id', element: <CandidateApplications /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <PasswordReset /> },
        { path: 'job-applicants/:id', element: <JobApplicants /> },
        { path: 'job-form', element: <NewJobPosting /> },
        { path: 'company-details/:id', element: <CompanyMaster /> },
        { path: 'add-staff/:id', element: <CompanyStaffForm /> },
        { path: 'staff-details/:id', element: <CompanyStaffForm /> },
        { path: 'manage-user', element: <ManageUser /> },
        { path: 'feedback', element: <FeedbackList /> },
      ];
      break;

    case 'recruiter':
      children = [
        { path: 'home', element: <JobList /> },
        { path: 'candidates', element: <CandidatesList /> },
        { path: 'candidate-applications/:id', element: <CandidateApplications /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <PasswordReset /> },
        { path: 'job-applicants/:id', element: <JobApplicants /> },
        { path: 'job-form', element: <NewJobPosting /> },
        { path: 'company-details/:id', element: <CompanyMaster /> },
        { path: 'company-profile', element: <CompanyMaster /> },
        { path: 'add-staff/:id', element: <CompanyStaffForm /> },
        { path: 'staff-details/:id', element: <CompanyStaffForm /> },
        { path: 'manage-user', element: <ManageUser /> },
        { path: 'feedback', element: <FeedbackList /> },
      ];
      break;

    case 'admin':
      children = [
        { path: 'home', element: <JobList /> },
        { path: 'candidates', element: <CandidatesList /> },
        { path: 'candidate-applications/:id', element: <CandidateApplications /> },
        { path: 'companies', element: <CompanyList /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <PasswordReset /> },
        { path: 'job-applicants/:id', element: <JobApplicants /> },
        { path: 'user-manage', element: <UserManagement /> },
        { path: 'job-form', element: <NewJobPosting /> },
        { path: 'company-profile', element: <CompanyMaster /> },
        { path: 'company-details/:id', element: <CompanyMaster /> },
        { path: 'add-staff/:id', element: <CompanyStaffForm /> },
        { path: 'staff-details/:id', element: <CompanyStaffForm /> },
        { path: 'feedback', element: <FeedbackList /> },
      ];
      break;
    case 'superadmin':
      children = [
        { path: 'home', element: <JobList /> },
        { path: 'candidates', element: <CandidatesList /> },
        { path: 'candidate-applications/:id', element: <CandidateApplications /> },
        { path: 'companies', element: <CompanyList /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <PasswordReset /> },
        { path: 'job-applicants/:id', element: <JobApplicants /> },
        { path: 'user-manage', element: <UserManagement /> },
        { path: 'job-form', element: <NewJobPosting /> },
        { path: 'company-profile', element: <CompanyMaster /> },
        { path: 'company-details/:id', element: <CompanyMaster /> },
        { path: 'add-staff/:id', element: <CompanyStaffForm /> },
        { path: 'staff-details/:id', element: <CompanyStaffForm /> },
        { path: 'feedback', element: <FeedbackList /> },
      ];
      break;
    default:
      break;
  }

  const otherPaths = [
    {
      path: `${process.env.REACT_APP_HOMEPAGE}register`,
      element: <Register />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}forgot-password`,
      element: <ForgotPassword />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}loginlink/:token`,
      element: <RedirectPage />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}enter-otp`,
      element: <EnterOTP />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}reset-password`,
      element: <ResetPassword />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}register`,
      element: <Register />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}forgot-password`,
      element: <ForgotPassword />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}reset-password`,
      element: <ResetPassword />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}404`,
      element: <Page404 />
    },
    {
      path: '*',
      element: <Navigate to={user ? `${process.env.REACT_APP_HOMEPAGE}404` : `${process.env.REACT_APP_HOMEPAGE}`} replace />,
    }
  ];

  const routes = useRoutes([
    {
      path: `${process.env.REACT_APP_HOMEPAGE}`,
      element: (user ? <DashboardLayout /> : <LoginPage />),
      children: user ? [
        ...children,
        { element: <Navigate to={`${process.env.REACT_APP_HOMEPAGE}home`} />, index: true }
      ] : [],
    },
    ...otherPaths,
  ]);

  return routes;

}