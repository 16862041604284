import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { changeNameFormat } from 'utils/Utils';
import { useAuth } from 'hooks/useAuthContext';
import dayjs from 'dayjs';
const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;

const ResumeDetailFormat = ({ summary, educations, certificates, workExperience, pdfRef }) => {
  const { user, updateContext } = useAuth();

  function formatExperience(experience) {
    if (!experience) return 'Present';
  
const experienceParts = experience.toString().split('.');
const years = parseInt(experienceParts[0], 10); // Whole number part as years
const decimalPart = experienceParts[1] ? experienceParts[1].substring(0, 2) : '0'; 
  
const months = parseInt(decimalPart, 10); // Convert decimal to months

// Create a formatted string for years and months
let yearLabel = years === 1 ? 'year' : 'years';
let monthLabel = months === 1 ? 'month' : 'months';

if (years > 0 && months > 0) {
  return `${years} ${yearLabel} and ${months} ${monthLabel}`;
} else if (years > 0) {
  return `${years} ${yearLabel}`;
} else if (months > 0) {
  return `${months} ${monthLabel}`;
}
    return 'Less than a month';
  }

  return (
    <CardContent>
      <Box className="overview-container" >
        <Box ref={pdfRef}>
        <Box>
          <Stack direction="row" spacing={1} alignItems="start" mb={1}>
            <Box sx={{ width: '100%' }}>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Box>
                  <Typography className="candidate-name">{changeNameFormat(user?.name)}</Typography>
                  <Typography className="candidate-email-contact">
                    {user?.email} {user?.phone ? `| +91 -${user?.phone}` : '' }
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>

          {summary && (
            <>
              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
              <Box className="candi-personal-det-row education-row">
                <Typography className="title">Professional Summary</Typography>
                <Typography className="candidate-summary">{summary}</Typography>
              </Box>
            </>
          )}
        </Box>
        {/* Candidate education block */}
        {educations && educations?.length > 0 && (
          <Box>
            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
            <Box className="candi-personal-det-row education-row">
              <Typography className="title">Education</Typography>
              {educations?.filter((item) => item._id)?.map((item, index) => (
                  <React.Fragment key={item?._id || index}>
                    <Typography className="school-uni-name">{item?.education_institute_name || ''}</Typography>
                    <Box mb={1}>
                      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Box mt={0.5} mb={1} className="edu-degree-type">
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="degree-name" variant="p">
                              {item?.degree_received || ''}
                            </Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography className="degree-type" variant="p">
                              {item?.field_of_study || ''}
                            </Typography>
                          </Stack>
                        </Box>
                        <Typography className="year-of-passout" variant="p" gutterBottom>
                          {dayjs(item?.education_from).format('YYYY')}-{dayjs(item?.education_to).format('YYYY') || '-'}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box className="personal-edu-text">
                      <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                        {item?.key_accomplishments &&
                          item?.key_accomplishments?.map((accomplishment, keyIndex) => (
                            <ListItem sx={{ display: 'list-item' }} disablePadding key={accomplishment || keyIndex}>
                              <Typography className="educational-info-txt" variant="body2" gutterBottom>
                                {accomplishment}
                              </Typography>
                            </ListItem>
                          ))}
                      </List>
                    </Box>
                  </React.Fragment>
                ))}
            </Box>
          </Box>
        )}

        {/* Candidate certification block */}

        {certificates && certificates?.length > 0 && (
          <Box>
            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
            <Box className="candi-personal-det-row certifi-row">
              <Typography className="title">Certification</Typography>
              {certificates?.filter((item) => item?._id)?.map((item, index) => (
                  <React.Fragment key={item?._id || index}>
                    <Box className="candi-certi-info">
                      <Typography className="university-name">{item?.education_institute_name || ''}</Typography>
                      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Box mt={0.5} mb={1} className="edu-degree-type">
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography className="degree-name" variant="p">
                              {item?.certificate_received || ''}
                            </Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography className="degree-type" variant="p">
                              {item?.field_of_study || ''}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={1}>
                            <Box>
                              <Box component="img" alt="certification" src={certificationIcon} />
                            </Box>
                            <Typography className="year-of-passout" variant="p" gutterBottom>
                              {dayjs(item?.education_to).format('YYYY') || '-'}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  </React.Fragment>
                ))}
            </Box>
          </Box>
        )}

        {/* Candidate work experience block */}
        {workExperience && workExperience?.length > 0 && (
          <Box>
            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
            <Box className="candi-personal-det-row work-exp">
              <Typography className="title">Work Experience</Typography>
              {workExperience?.filter((item) => item?._id)?.map((data, index) => (
                  <Box key={data?._id || index}>
                    {data?.organization_name === 'Work Gap' ? (
                      data?.show_in_resume && (
                        <Box>
                          <Box mb={1}>
                            <Typography className="post-name">{data?.highest_designation}</Typography>
                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                              <Box mt={0.5} mb={1} className="exp-company-info">
                                <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                  <Typography className="company-name" variant="p">
                                    {data?.gap_explaination}
                                  </Typography>
                                  <Typography variant="body1">•</Typography>
                                  <Typography className="job-type" variant="p">
                                    Full Time
                                  </Typography>
                                </Stack>
                              </Box>

                              <Typography className="working-year" variant="p" gutterBottom>
                                {dayjs(data?.work_history_from).format('MMM YYYY')} -{' '}
                                {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YYYY')}
                              </Typography>
                            </Stack>
                            <Box className="personal-work-text">
                              <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                {data?.key_accomplishments &&
                                  data?.key_accomplishments?.map((accomplishment, keyIndex) => (
                                    <ListItem
                                      sx={{ display: 'list-item' }}
                                      disablePadding
                                      key={accomplishment || keyIndex}
                                    >
                                      <Typography className="work-info-txt" variant="body2" gutterBottom>
                                        {accomplishment}
                                      </Typography>
                                    </ListItem>
                                  ))}
                              </List>
                            </Box>
                          </Box>
                        </Box>
                      )
                    ) : (
                      <Box>
                        <Box mb={1}>
                          <Typography className="post-name">{data?.highest_designation}</Typography>
                          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Box mt={0.5} mb={1} className="exp-company-info">
                              <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                <Typography className="company-name" variant="p">
                                  {data?.organization_name}
                                </Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography className="job-type" variant="p">
                                  {data?.job_type}
                                </Typography>
                              </Stack>
                            </Box>
                            <Typography className="working-year" variant="p" gutterBottom>
                              {dayjs(data?.work_history_from).format('MMM YYYY')} -{' '}
                              {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YYYY')}
                            </Typography>
                          </Stack>
                          <Box className="personal-work-text">
                            <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                              {data?.key_accomplishments &&
                                data?.key_accomplishments?.map((accomplishment, keyIndex) => (
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                    key={accomplishment || keyIndex}
                                  >
                                    <Typography className="work-info-txt" variant="body2" gutterBottom>
                                      {accomplishment}
                                    </Typography>
                                  </ListItem>
                                ))}
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>

            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
            {/* Candidate Highlighted Skills */}
            <Box className="highlighted-skills" mb={3}>
              <Typography className="title">Highlighted Skills</Typography>

              <Card variant="outlined" sx={{ borderRadius: '12px' }}>
                <CardContent sx={{ padding: 0 }}>
                  <Box mb={1} className="header-block" sx={{ padding: '0 0.5rem' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Typography variant="p" gutterBottom>
                          Skill
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="p" gutterBottom>
                          Sub-Skill
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="p" gutterBottom>
                          Used / Learnt at
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="p" gutterBottom>
                          Years Used
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {workExperience?.map(
                    (data, index) =>
                      data?.organization_name !== 'Work Gap' && (
                        <Box className="text-block" sx={{ padding: '0 0.5rem' }}>
                          {index !== 0 && (
                            <Divider sx={{ borderWidth: '0.01', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                          )}
                          <Box className="text-block" sx={{ padding: '0 0.5rem' }} key={data?._id || index}>
                            <Grid container alignItems={'center'} spacing={1}>
                              <Grid item xs={2}>
                                <Typography variant="p" gutterBottom>
                                  {data?.highest_designation}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="p" gutterBottom>
                                  {data?.skills?.join(', ')}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="p" gutterBottom>
                                  {data?.organization_name}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="p" gutterBottom>
                                  {formatExperience(data.work_experience)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      )
                  )}
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}
        
        </Box>
      </Box>
    </CardContent>
  );
};
export default ResumeDetailFormat;
