import React, { useState, useEffect } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CreateUpdateEducation from '../job-application/CreateUpdateEducation';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewJobSchema, NewCompanyJobSchema } from 'models/NewJobSchema';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import FormProvider from 'hooks/form/form-provider';
import AddIcon from '@mui/icons-material/Add';
import AddSkills from '../basic-profile/AddSkills';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import QualificationOptions from 'components/QulificationOptions';
import { createJob, jobUpdated, getCompanyDetails, jobDetails } from 'api/job';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreeningQuestionDrawer from './ScreeningQuestionDrawer';
import TypeAheadPlain from '../TypeAheadPlain';
import { changeNameFormat } from 'utils/Utils';
import cityData from 'data/city.json';
import { formatCurrency } from 'utils/Utils';

const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;

const NewJobPosting = () => {
  const backButtonIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={backIcon} />
    </Icon>
  );
  // jobs type toggle buttons
  const [alignment, setAlignment] = React.useState('tj-jobs');
  const navigate = useNavigate();
  const [allCertifications, setAllCertifications] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createdBy, setCreatedBy] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [jobUniqueId, setJobUniqueId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const location = useLocation();
  const id = location.state;
  const isEditMode = !!id;
  const [addMoreSkills, setAddMoreSkills] = useState([]);
  const [chipData, setChipData] = useState([]);

  const jobType = [{ label: 'Full time' }, { label: 'Part time' }, { label: 'Temporary' }, { label: 'Internship' }, { label: 'Trainee' }, { label: 'Freelancer (Self employed)' }];
  const preferredJobShifts = ['Any', 'Daytime', 'Evening', 'Night'];
  const workModelFormat = ['In office', 'Work from Home', 'Hybrid'];
  const disabilityData = [
    { label: 'Blindness' }, { label: 'Low vision' },
    { label: 'Hearing impairment' },
    { label: 'Locomotor disability' },
    { label: 'Leprosy cured' },
    { label: 'Cerebral Palsy' },
    { label: 'Intellectual disability' },
    { label: 'Mental illness' },
    { label: 'Muscular dystrophy' },
    { label: 'Parkinson\'s disease' },
    { label: 'Acid attack victim' },
    { label: 'Sickle cell disease' },
    { label: 'Hemophilia' },
    { label: 'Thalassemia' },
    { label: 'Speech and language disability' },
    { label: 'Multiple sclerosis' },
    { label: 'Specific learning disabilities' },
    { label: 'Chronic neurological conditions' },
    { label: 'Autism spectrum disorder' },
    { label: 'Dwarfism' }];

  const defaultValues = {
    title: '',
    company: '',
    function: '',
    role: '',
    description: '',
    job_overview: '',
    qualifications: [],
    certifications: [],
    key_skills: [],
    experience: '',
    work_mode: 'In office',
    job_type: 'Full time',
    job_shift: 'Daytime',
    company_overview: '',
    location: '',
    selectCompany: null,
    compensation: '',
    industry_sector: '',
    start_date: null,
    reporting_to: '',
    direct_reports: null,
    question: [],
    salary: '',
    status: 'active',
    notice_period: '',
    company_id: '',
    company_identification_number: '',
    company_website: '',
    external_link: '',
    job_responsibilities: '',
    gender_preference: '',
    disability_preference: '',
    is_company_name_confidential: false,
    is_compensation_confidential: false,
    all_skills: [],
  };
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(
      user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter'
        ? NewCompanyJobSchema(alignment)
        : NewJobSchema(alignment)
    ),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;
  const min = watch('min');
  const max = watch('max');

  const [openCUEducDrawer, setOpenCUEducDrawer] = React.useState(false);
  const [isOtpDialogOpen, setOtpDialogOpen] = useState(false);
  const [keyQuestions, setKeyQuestions] = useState(['']);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEditMode) {
          await fetchJobDetails(id);
        } else {
          await createNewJob();
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch data', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (min !== undefined && max !== undefined) {
      setValue('experience', `${min}-${max}`);
    }
  }, [min, max, setValue]);

  const createUpdateEduDrawer = (newOpen) => () => {
    setOpenCUEducDrawer(newOpen);
  };

  const handleAddMoreSkills = (moreSkills) => {
    if (moreSkills?.length > 0) {
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
    }
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // chip delete
  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const createNewJob = async () => {
    try {
      const response = await createJob({ created_by: 'Admin Name', status: 'New' });
      if (response?.success && response?.data) {
        setJobUniqueId(response.data?._id);
        setJobId(response.data?.jobid);
        setCreatedBy(user?.name);
        setUpdatedAt(response.data?.updatedAt);
        if (user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter') {
          const companyId = user?.id;
          // setValue('selectCompany', { name: user?.name, id: companyId });
          const companyResponse = await getCompanyDetails({ id: companyId });
          if (companyResponse?.success && companyResponse?.data) {
            const companyData = companyResponse.data;
            setValue('company', companyData.full_name);
            setValue('company_id', companyData._id);
            setValue('industry_sector', companyData.industry_sector);
            setValue('company_overview', companyData.description || '');
          } else {
            enqueueSnackbar(companyResponse.message, { variant: 'error' });
          }
        }
      } else {
        enqueueSnackbar('Failed to create job', { variant: 'error' });
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchJobDetails = async (id) => {
    try {
      const response = await jobDetails({ id });
      if (response?.success && response?.data) {
        const {
          _id,
          jobid,
          created_by,
          updatedAt,
          company_id,
          company,
          question,
          certifications,
          key_skills,
          qualifications,
          experience,
          all_skills
        } = response.data;
        setJobData(response?.data);
        setJobUniqueId(_id);
        setJobId(jobid);
        setCreatedBy(created_by);
        setUpdatedAt(updatedAt);
        reset(response.data);
        setValue('certifications', '');
        // if (company_id) setValue('selectCompany', { name: company, id: company_id });
        if (question && Array.isArray(question)) {
          setKeyQuestions([...question]);
          setAllCertifications(certifications || []);
          setValue('question', question);
          setChipData((key_skills && key_skills?.length > 0) ? key_skills : all_skills);
          setSelectedChips(key_skills);
          setSelectedQualifications(qualifications);
          const [min, max] = experience.split('-').map(Number);
          setValue('min', min);
          setValue('max', max);
          setValue('all_skills', all_skills);
          setAddMoreSkills(all_skills)
        }
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      throw error;
    }
  };

  const [jobData, setJobData] = useState({});
  const [showFlag, setShowFlag] = useState('');

  const onSubmit = handleSubmit(async (formData) => {
    if (!validateSkills(selectedChips)) {
      return;
    } else {
      setShowFlag('');
    }
    formData.qualifications = selectedQualifications;
    formData.certifications = allCertifications;
    formData.key_skills = selectedChips;

    setJobData(formData);    
    setOpenCUEducDrawer(true);

  });

  const removeQualification = (index) => {
    const updatedQualifications = [...selectedQualifications];
    updatedQualifications.splice(index, 1);
    setSelectedQualifications(updatedQualifications);
  };

  const screeningQueDrawer = (newOpen) => () => {
    setOpenCUEducDrawer(newOpen);
  };

  const handleQuestions = (qus) => {
    setKeyQuestions(qus);
    setValue('question', qus);
  };

  const handleClick = (label) => {
    let totalSkillls;
    if (selectedChips.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
    } else {
      if (selectedChips.length >= 10) {
        setShowFlag('You can select up to 10 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const removeCertification = (index) => {
    const updatedCertification = [...allCertifications];
    updatedCertification.splice(index, 1);
    setAllCertifications(updatedCertification);
  };

  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const onReset = () => {
    reset(defaultValues);
    setAllCertifications([]);
    setKeyQuestions(['']);
    setSelectedQualifications([]);
    setSelectedChips([]);
    setAddMoreSkills([]);
  };

  const saveAsDraft = async () => {
    const data = watch();
    const filledData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== '' && value !== null));
    filledData.status = 'draft';
    const response = await jobUpdated({ formData: filledData, job_id: jobUniqueId });
    if (response?.success === true && response?.data) {
      enqueueSnackbar('Draft job details saved successfully.', { variant: 'success' });
      navigate('/home');
    } else if (response?.success === false) {
      enqueueSnackbar('Failed to update job', { variant: 'error' });
      console.error('Failed to update job:', response.message);
    }
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 5) {
      setShowFlag('Please select at least 5 skills.');
      return false;
    } else if (totalSkillls?.length > 10) {
      setShowFlag('You can select up to 10 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  return (
    <Box>
      {/* <Header /> */}

      <Box className="admin-new-job-posting">
        <Container maxWidth="xl">
          <Box sx={{ padding: '5rem 1.5rem' }}>
            {/* <Link href="#" className="update-link" underline="none" onClick={createUpdateEduDrawer(true)}>
              open side panel
            </Link> */}
            <Box mb={1}>
              <Button size="small" className="back-btn" startIcon={backButtonIcon} component={RouterLink} to="/home">
                Back to Job Listing
              </Button>
            </Box>
            <Box mb={4}>
              <Typography className="page-title" mb={3}>
                New Job Posting
              </Typography>
              <Box mb={2}>
                <ToggleButtonGroup
                  className="job-post-toggle-btn"
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                  sx={{ gap: '15px' }}
                >
                  <ToggleButton value="tj-jobs">TJ Jobs</ToggleButton>
                  <ToggleButton value="external-job-posting">External Job Posting</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {/* Company Details */}
              <Box mb={4}>
                <Box className="card-container">
                  <Box className="card-title">
                    <Typography className="title-text">Recruitment Company Details</Typography>
                  </Box>
                  <Box className="card-body">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          {user?.user_type == 'admin' || user?.user_type == 'superadmin' && (
                            <Controller
                              control={control}
                              name="company"
                              defaultValue={defaultValues?.company || ''}
                              render={({ field }) =>
                                isEditMode ? (
                                  <TextField
                                    {...field}
                                    {...register('company')}
                                    fullWidth
                                    size="small"
                                    label="Company Name"
                                    variant="outlined"
                                    InputProps={{ readOnly: true }}
                                    value={field?.value || ''}
                                  />
                                ) : (
                                  <TypeAhead
                                    {...field}
                                    {...register('company')}
                                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.registeredCompany}`}
                                    multiple={false}
                                    fullWidth
                                    label="Company Name"
                                    displayTextKey="name"
                                    placeholder="Company Name"
                                    onChange={(e, value) => {
                                      if (value) {
                                        field.onChange(value?.name);
                                        setValue('company_id', value?.id || '');
                                        setValue('industry_sector', value?.industry_sector || '');
                                        setValue('company_overview', value?.company_overview || '');
                                        setValue('company_identification_number', value?.identification_number || '');
                                      }
                                    }}
                                    error={!!errors.full_name}
                                    helperText={errors.full_name?.message}
                                  />
                                )
                              }
                            />
                          )}
                        </FormControl>
                        <Controller
                          name="is_company_name_confidential"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => field.onChange(e.target.checked)}
                                />
                              }
                              label="Keep company name confidential"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Controller
                            name="company_identification_number"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                error={!!errors.company_identification_number}
                                helperText={errors.company_identification_number?.message}
                                label="Company Identification Number (CIN)"
                                variant="outlined"
                                fullWidth
                                multiline
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} mb={3}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Controller
                            name="industry_sector"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                error={!!errors.industry_sector}
                                helperText={errors.industry_sector?.message}
                                label="Industry Sector"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Controller
                            name="company_website"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                error={!!errors.company_website}
                                helperText={errors.company_website?.message}
                                label="Company Website"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <FormControl fullWidth>
                      <Controller
                        name="company_overview"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            error={!!errors.company_overview}
                            label="Company Overview"
                            variant="outlined"
                            fullWidth
                            multiline
                            helperText="2 / 30 words"
                          />
                        )}
                      />
                      {errors?.company_overview && (
                        <FormHelperText error>{errors?.company_overview?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              {/* Job Details */}
              <Box mb={4}>
                <Box className="card-container">
                  <Box className="card-title">
                    <Typography className="title-text">Job Details</Typography>
                  </Box>

                  <Box className="card-body">
                    <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                      <TextField
                        size="small"
                        {...register('title')}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        label="Title"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: Boolean(watch('title')) }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                      <TextField
                        size="small"
                        {...register('job_overview')}
                        error={!!errors.job_overview}
                        helperText={errors.job_overview?.message}
                        label="Job Summary"
                        variant="outlined"
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: Boolean(watch('job_overview')) }}
                      />
                      <Typography component="p" className="need-help-txt">
                        Need help with some examples?
                        <Link href="#"> Click Here</Link>
                      </Typography>
                    </FormControl>
                    {/* description */}
                    <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
                      <TextField
                        size="small"
                        {...register('description')}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        label="Job Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: Boolean(watch('description')) }}
                      />
                    </FormControl>
                    <Box mb={1} />
                    <Grid container columnSpacing={4}>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                          <Controller
                            control={control}
                            name="role"
                            defaultValue={defaultValues?.role || ''}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                {...field}
                                {...register('role')}
                                label="Designation"
                                variant="outlined"
                                fullWidth
                                error={!!errors.role}
                                helperText={errors.role ? errors.role.message : ''}
                                onChange={(e) =>
                                  setValue("role", changeNameFormat(e.target.value), { shouldDirty: true })
                                }
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.job_type}>
                          <InputLabel size='small' id="job_type">Job Type</InputLabel>
                          <Controller
                            control={control}
                            name="job_type"
                            defaultValue={defaultValues?.job_type || ''}
                            render={({ field }) => (
                              <Select
                                {...field}
                                {...register('job_type')}
                                labelId="job_type"
                                error={!!errors.job_type}
                                helperText={errors.job_type?.message}
                                label="Job Type"
                                variant="outlined"
                                fullWidth
                              >
                                {jobType?.map((item) =>
                                  <MenuItem value={item?.label}>{item?.label}</MenuItem>
                                )}
                              </Select>
                            )}
                          />
                          <Typography variant="caption" color="error">
                            {errors?.job_type?.message}
                          </Typography>
                        </FormControl>

                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.work_mode}>
                          <InputLabel size="small" id="work_mode">
                            Job Format
                          </InputLabel>
                          <Controller
                            control={control}
                            name="work_mode"
                            defaultValue={defaultValues?.work_mode || ''}
                            render={({ field }) => (
                              <Select
                                {...field}
                                {...register('work_mode')}
                                labelId="work_mode"
                                error={!!errors.work_mode}
                                helperText={errors.work_mode?.message}
                                label="Job Shift"
                                variant="outlined"
                                fullWidth
                              >
                                {workModelFormat?.map((item) =>
                                  <MenuItem value={item}>{item}</MenuItem>
                                )}
                              </Select>
                            )}
                          />
                          {errors?.work_mode && <FormHelperText error>{errors.work_mode?.message}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                          <Controller
                            control={control}
                            name="function"
                            render={({ field }) => (
                              <TypeAhead
                                {...field}
                                {...register('function')}
                                api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.roles}`}
                                label="Function"
                                size="small"
                                displayTextKey="name"
                                freeSolo={true}
                                onInputChange={(e, value) => {
                                  setValue("function", changeNameFormat(value), { shouldDirty: true });
                                }}
                                onChange={(e, value) => {
                                  setValue('all_skills', value?.skills);
                                  field.onChange(value?.name);
                                  setChipData(value?.skills);
                                  setAddMoreSkills(value?.skills);
                                  setSelectedChips([]);
                                }}
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.job_shift}>
                          <InputLabel size="small" id="job_shift">
                            Job Shift
                          </InputLabel>
                          <Controller
                            name="job_shift"
                            control={control}
                            render={({ field }) => (
                              <Select {...field} size="small" label="Job Shift" variant="outlined" fullWidth>
                                {preferredJobShifts?.map((item) =>
                                  <MenuItem value={item}>{item}</MenuItem>
                                )}
                              </Select>
                            )}
                          />
                          {errors.job_shift && <FormHelperText error>{errors.job_shift?.message}</FormHelperText>}
                        </FormControl>

                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                          <Controller
                            control={control}
                            name="location"
                            render={({ field }) => (
                              <TypeAhead
                                {...field}
                                data={cityData}
                                label="Location"
                                displayTextKey="name"
                                disableClearable={true}
                                onChange={(e, value) => field.onChange(value)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                          <TextField
                            size="small"
                            {...register('direct_reports')}
                            error={!!errors.direct_reports}
                            helperText={errors.direct_reports?.message}
                            label="Number of people reporting"
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: Boolean(watch('direct_reports')) }}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {alignment === 'external-job-posting' && (
                        <Grid item xs={6}>
                          <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                            <Controller
                              name="external_link"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  size="small"
                                  error={!!errors.external_link}
                                  helperText={errors.external_link?.message}
                                  label="Job Application Link"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={alignment === 'tj-jobs' ? 6 : 12}>
                        <FormControl fullWidth>
                          <Controller
                            name="job_responsibilities"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                error={!!errors.job_responsibilities}
                                helperText={errors.job_responsibilities?.message}
                                label="Job Responsibilities"
                                variant="outlined"
                                fullWidth
                                multiline
                              />
                            )}
                          />{' '}
                          <Typography component="p" className="need-help-txt">
                            Need help with some examples?
                            <Link href="#"> Click Here</Link>
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              {/* Job Requirements */}
              <Box mb={4}>
                <Box className="card-container">
                  <Box className="card-title">
                    <Typography className="title-text">Job Requirements</Typography>
                  </Box>

                  <Box className="card-body">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                          <Controller
                            control={control}
                            name="qualifications"
                            render={({ field }) => (
                              <>
                                <QualificationOptions
                                  onAdd={(data) => {
                                    const isNameIncluded = selectedQualifications?.some((qualification) =>
                                      qualification?.values[0]?.includes(data?.values[0])
                                    );
                                    if (!isNameIncluded) {
                                      const updatedQualifications = [...selectedQualifications, data];
                                      setSelectedQualifications(updatedQualifications);
                                      setValue('qualifications', [...(defaultValues?.qualifications || []), data]);
                                      trigger('qualifications');
                                    }
                                  }}
                                  error={!!errors.qualifications}
                                  helperText={errors.qualifications?.message}
                                />
                                <Stack direction="row" mt={1} sx={{ flexWrap: 'wrap', rowGap: 1 }}>
                                  {selectedQualifications.map((qualification, index) => (
                                    <Chip
                                      key={index}
                                      label={qualification?.values?.join(', ')}
                                      size="small"
                                      onDelete={() => removeQualification(index)}
                                    />
                                  ))}
                                </Stack>
                              </>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <Controller
                            name={'certifications'}
                            control={control}
                            defaultValue={defaultValues?.certifications || []}
                            render={({ field }) => (
                              <TypeAheadPlain
                                value={defaultValues?.certifications || []}
                                onChange={(e, value) => {
                                  if (value && value[0]?.name) {
                                    setAllCertifications((prevSelectedCertifications) => {
                                      if (!prevSelectedCertifications.includes(value[0].name)) {
                                        return [...prevSelectedCertifications, value[0].name];
                                      }
                                      return prevSelectedCertifications;
                                    });
                                  }
                                }}
                                api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.certificate}`}
                                multiple={true}
                                fullWidth
                                label="Certification"
                                placeholder="Certification"
                                displayTextKey={'name'}
                              />
                            )}
                          />
                          <Stack direction="row" mt={1} sx={{ flexWrap: 'wrap', rowGap: 1 }}>
                            {allCertifications.map((qualification, index) => (
                              <Chip
                                size="small"
                                key={index}
                                label={qualification}
                                onDelete={() => removeCertification(index)}
                              />
                            ))}
                          </Stack>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} alignItems={'flex-end'}>
                      <Grid item xs={6}>
                        <Typography className="label-text" mb={1}>
                          Experience Required (in years)
                        </Typography>

                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                              <Controller
                                name="min"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    label="Min Experience"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={!!errors.min}
                                    helperText={errors.min ? errors.min.message : ''}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                              <Controller
                                name="max"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    label="Max Experience"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={!!errors.max}
                                    helperText={errors.max ? errors.max.message : ''}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{ marginBottom: '1.5rem' }}
                          error={!!errors.notice_period}
                        >
                          <InputLabel size="small" id="notice_period">
                            Maximum Notice Period (in days)
                          </InputLabel>
                          <Controller
                            name="notice_period"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                size="small"
                                label=" Maximum Notice Period (in days)"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="0 days (immediate)">0 days (immediate)</MenuItem>
                                <MenuItem value="15">15 Days</MenuItem>
                                <MenuItem value="30">30 Days</MenuItem>
                                <MenuItem value="45">45 Days</MenuItem>
                                <MenuItem value="60">60 Days</MenuItem>
                                <MenuItem value="75">75 Days</MenuItem>
                                <MenuItem value="90">90 Days</MenuItem>
                                <MenuItem value="90+">90+ Days</MenuItem>
                              </Select>
                            )}
                          />
                          {errors.notice_period && (
                            <FormHelperText error>{errors.notice_period.message}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" error={!!errors.salary}>
                          <Controller
                            control={control}
                            name="salary"
                            defaultValue={defaultValues?.salary || ''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="salary"
                                name="salary"
                                label="Maximum Annual Compensation"
                                variant="outlined"
                                size="small"
                                value={field.value ? formatCurrency(field.value) : ''}
                                error={!!errors.salary}
                                helperText={errors.salary?.message}
                                onChange={(e) => {
                                  const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                                  field.onChange(rawValue);
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <Controller
                          name="is_compensation_confidential"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => field.onChange(e.target.checked)}
                                />
                              }
                              label="Keep compensation confidential"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" error={!!errors.compensation}>
                          <InputLabel size="small" id="compensation">
                            Compensation Type
                          </InputLabel>
                          <Controller
                            name="compensation"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select {...field} size="small" label="Compensation Type" variant="outlined" fullWidth>
                                <MenuItem value="All Fixed">All Fixed</MenuItem>
                                <MenuItem value="Fixed + Variable">Fixed + Variable</MenuItem>
                                <MenuItem value="Fixed + Variable">Fixed + Variable + Other Perks</MenuItem>
                              </Select>
                            )}
                          />
                          {errors.compensation && <FormHelperText error>{errors.compensation?.message}</FormHelperText>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              {/* Required Expertise / Skills */}
              <Box mb={4} className="job-posting-skills">
                <Box className="card-container">
                  <Box className="card-title">
                    <Stack>
                      <Typography className="title-text">Required Expertise / Skills</Typography>
                      <Typography className="info-text">Please add 5-10 relevant skills</Typography>
                    </Stack>
                  </Box>

                  <Box className="card-body">
                    <Stack direction="row" flexWrap="wrap">
                      {chipData?.map((label, index) => (
                        <Chip
                          key={index}
                          label={label}
                          size="small"
                          clickable
                          variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                          style={{
                            color: selectedChips.includes(label) ? 'white' : 'black',
                            backgroundColor: selectedChips.includes(label) ? '#2eb02a' : '#1575C6',
                            // border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                          }}
                          onClick={() => handleClick(label)}
                        />
                      ))}
                    </Stack>
                    {showFlag && (
                      <Typography style={{ color: 'red', fontSize: 'small' }}>
                        {showFlag}
                      </Typography>
                    )}
                    <Link className="add-more-skills-link" size="small" onClick={handleOpenOtpDialog} underline="none">
                      Add More Skills
                    </Link>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="otp-dialog-wrapper"
                    >
                      <AddSkills
                        onClose={handleClose}
                        skills={addMoreSkills}
                        selectedskills={selectedChips}
                        onAddSkills={handleAddMoreSkills}
                        type={'jobType'}
                      />
                    </Dialog>
                  </Box>
                </Box>
              </Box>

              {/* Special Requirements */}
              <Box mb={5}>
                <Box className="card-container">
                  <Box className="card-title">
                    <Stack>
                      <Typography className="title-text">Special Requirements</Typography>
                      <Typography className="info-text">
                        Please mention if you are looking for specific candidate type
                      </Typography>
                    </Stack>
                  </Box>

                  <Box className="card-body">
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" error={!!errors.gender_preference}>
                          <InputLabel size="small" id="gender_preference">
                            Gender preference
                          </InputLabel>
                          <Controller
                            name="gender_preference"
                            control={control}
                            render={({ field }) => (
                              <Select {...field} size="small" label="Gender preference" variant="outlined" fullWidth>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            )}
                          />
                          {errors.gender_preference && (
                            <FormHelperText error>{errors.gender_preference?.message}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" error={!!errors.disability_preference}>
                          <Controller
                            control={control}
                            name="disability_preference"
                            render={({ field }) => (
                              <Autocomplete
                                size="small"
                                {...field}
                                {...register('disability_preference')}
                                id="disability_preference"
                                name="disability_preference"
                                options={disabilityData?.map((item) => item?.label)}
                                renderInput={(params) => <TextField {...params} label="Disability preference" />}
                                onChange={(e, value) => {
                                  field.onChange(value);
                                }}
                                inputValue={field.value || ''}
                                onInputChange={(e, value) => field.onChange(value)}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              {/* Questions Section */}
              {/* <Box mb={5}>
                <Box className="card-container">
                  <Box className="card-title">
                    <Stack>
                      <Typography className="title-text">Questions for Candidate Assessment</Typography>
                    </Stack>
                  </Box>

                  <Box className="card-body">
                    <Button variant="outlined" size="small" onClick={screeningQueDrawer(true)}>
                      Add Questions
                    </Button>
                    {errors.question && <FormHelperText error>{errors.question?.message}</FormHelperText>}
                  </Box>
                </Box>
              </Box> */}

              <Box>
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <Button
                        className="continue-btn"
                        variant="contained"
                        size="medium"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Continue
                      </Button>
                      {jobData?.status !== 'active' &&
                      <Button className="sad-btn" variant="outlined" size="medium" onClick={saveAsDraft}>
                        Save As Draft
                      </Button>}
                    </Stack>
                  </Box>
                  <Button className="reset-btn" size="medium" onClick={onReset}>
                    Reset
                  </Button>
                </Stack>
              </Box>
            </FormProvider>
          </Box>
        </Container>
      </Box>

      <Drawer open={openCUEducDrawer} anchor={'right'} onClose={screeningQueDrawer(false)}>
        <ScreeningQuestionDrawer
          onClose={screeningQueDrawer(false)}
          keyQuestions={keyQuestions}
          setJobQuestions={handleQuestions}
          jobData={jobData}
          jobUniqueId={jobUniqueId}
        />
      </Drawer>
    </Box>
  );
};

export default NewJobPosting;
