import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField, Checkbox, Dialog } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import Label from './label/Label';
import CustomValue from './CustomValue';

const TypeAhead = forwardRef(({ name, data, api, selectionKey, displayTextKey, defaultText, multiple, minInputLength = 1, isMatching = true, selectAsCheckbox, isCloseHide, isReadOnly, disableCloseOnSelect = false, onInputChange, onChange, ...rest }, ref) => {
  const isObject = !!displayTextKey;
  const { setValue, watch, trigger, formState: { errors } } = useFormContext();
  const [options, setOptions] = useState(data || []);
  const [loading, setLoading] = useState(false);
  const [optionText, setOptionText] = useState('Start Typing...');
  const [openDialog, setOpenDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const value = watch(name);

  const fetchData = async (inputValue) => {
    setLoading(true);
    try {
      const response = await fetch(`${api}?q=${inputValue}`);
      const resultData = await response.json();
      setOptions([...(resultData || [])]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=> {
    if (api && !options?.length && inputValue) setOptionText('No match found.');
  }, [options]);

   useEffect(() => {
    const dataFetching = async () => {
      if (api){
        try {
          const response = await fetch(`${api}`);
          const resultData = await response.json();
          setOptions([...(resultData || [])]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }

    dataFetching();    
   },[]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    if (event?.type === 'change') {
      if (onInputChange) onInputChange(event, newInputValue);
      if (api) {
        if (newInputValue?.length >= minInputLength) {
          fetchData(newInputValue);
        } else {
          setOptionText('Start Typing...');
        }
      }
    } else if (event?.type === "click" && api && !newInputValue) {
      setOptions([]);
    }
    // else if (event?.type === "focus" && !options?.length) {
    //   fetchData(newInputValue);
    // }
  };

  const handleCustomValue = (_, customValue) => {
    setOpenDialog(false);
    const result = isObject ? { [selectionKey]: customValue?.toLowerCase(), [displayTextKey]: customValue } : customValue;
    if (onChange) onChange(_, result);
    setValue(name, isObject ? result[displayTextKey] : result, { shouldDirty: true });
    trigger(name);
  };

  return (
    <>
      <Autocomplete
        {...rest}
        multiple={multiple}
        size="small"
        disablePortal
        openOnFocus
        noOptionsText={optionText}
        disableClearable={isCloseHide || false}
        options={options}
        readOnly={isReadOnly || false}
        getOptionLabel={(option) => typeof option === 'object' && displayTextKey ? option[displayTextKey] : option}
        value={multiple ? value || [] : value}
        disableCloseOnSelect={ disableCloseOnSelect }
        onChange={(_, newValue) => {
          if (newValue) {
            if (defaultText && isObject && typeof newValue === 'object' && displayTextKey ? newValue[displayTextKey] === defaultText : newValue === defaultText) {
              setOpenDialog(true);
            } else {
              const value = (typeof newValue === 'object' && (selectionKey || displayTextKey) ? (multiple ? (selectionKey ? newValue?.map(item => item[selectionKey] || item) : newValue?.map(item => item[displayTextKey] || item)) : (selectionKey ? newValue[selectionKey] : newValue[displayTextKey])) : newValue);
              if (onChange) onChange(_, newValue, value);
              setValue(name, value, { shouldDirty: true });
              trigger(name);
            }
          }
        }}
        filterOptions={defaultText ? (options, state) => {
          if (loading || !state?.inputValue || state?.inputValue?.length < minInputLength) return [];
          const filtered = options?.filter(option => {
            return isObject && typeof option === 'object' ? option[displayTextKey]?.toLowerCase()?.includes(state.inputValue?.toLowerCase()) : option?.toLowerCase().includes(state.inputValue?.toLowerCase())
          });
          return [...filtered, isObject ? { [selectionKey]: defaultText?.toLowerCase(), [displayTextKey]: defaultText } : defaultText];
        } : (api ? (options) => options : (options, state) => {
          return ( isObject && displayTextKey ? options?.filter(option => option[displayTextKey]?.toLowerCase()?.includes(state.inputValue?.toLowerCase())) : options?.filter(option => option?.toLowerCase()?.includes(state.inputValue?.toLowerCase())))
        })}
        loading={loading}
        clearOnBlur={false}
        onInputChange={handleInputChange}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={props?.id}>
              {selectAsCheckbox && <Checkbox
                icon={<CheckBoxOutlineBlank fontSize='small' />}
                checkedIcon={<CheckBox fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />}
              {typeof option === 'object' ? option[displayTextKey] : option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={rest?.label}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            inputRef={ref}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          const isMatching = typeof option === 'object' ? (option[selectionKey] ? option[selectionKey] === value : (option[displayTextKey] === value)) : option === value;
          return isMatching;
        }}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper">
        <CustomValue onClose={() => setOpenDialog(false)} title={rest?.placeholder} onCustomValue={handleCustomValue} label={rest?.label} />
      </Dialog>
    </>
  );
});

export default TypeAhead;
