import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

function TypeAheadPlain({ value, api, displayTextKey, selectionKey, multiple, onInputChange, onChange, ...rest }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   const dataFetching = async () => {
     if (api){
       try {
         const response = await fetch(`${api}`);
         const resultData = await response.json();
         setOptions([...(resultData || [])]);
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     }
   }

   dataFetching();    
  },[]);

  const fetchData = async (inputValue) => {
    setLoading(true);
    try {
      // Fetch data from API based on the input value
      const response = await fetch(`${api}?q=${inputValue}`);
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Autocomplete
      {...rest}
      size='small'
      disablePortal
      multiple={multiple} // Set multiple based on selectType
      options={options}
      getOptionLabel={(option) => typeof option === 'object' ? option[displayTextKey] : option}
      value={multiple ? value || [] : value}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        fetchData(newInputValue);
        if (onInputChange) onInputChange(event, newInputValue);
      }}
      onChange={(_, newValue) => {        
        const value = (typeof newValue === 'object' && (selectionKey || displayTextKey) ? (multiple ? (selectionKey ? newValue?.map(item => item[selectionKey] || item) : newValue?.map(item => item[displayTextKey] || item)) : (selectionKey ? newValue[selectionKey] : newValue[displayTextKey])) : newValue);
        if (onChange) onChange(_, newValue, value);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props?.id}>
            {typeof option === 'object' ? option[displayTextKey] : option}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={rest?.label}
          error={rest?.error}
          helperText={rest?.helperText}
        />
      )}
      filterOptions={(api ? (options) => options : (options, state) => {
        return ( isObject && displayTextKey ? options?.filter(option => option[displayTextKey]?.toLowerCase()?.includes(state.inputValue?.toLowerCase())) : options?.filter(option => option?.toLowerCase()?.includes(state.inputValue?.toLowerCase())))
      })}
    />
  );
}

export default TypeAheadPlain;
