import * as yup from 'yup';
// import parsePhoneNumber from 'libphonenumber-js';

// Schema for validation
export const LoginSchema = yup.object().shape({
  emailOrPhone: yup.string()
    .required('Please enter your WhatsApp number or email ID.')
    .test(
      'emailOrPhone',
      'Please enter a valid WhatsApp number or email ID.',
      function (value) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const isValidEmail = emailPattern.test(value);
        const isValidPhone = phoneRegExp.test(value);
        return isValidEmail || isValidPhone; // Return true if either is valid
      }
    ),
  password: yup
    .string()
    .required('Please enter your password.')
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Please enter correct email id.').required('Please enter your email id.'),
  otp: yup.number().min(6, "OTP should be 6 digits")
});