import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup,
} from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useForm, register, watch, Controller } from 'react-hook-form';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import FormProvider from 'hooks/form/form-provider';
import { updateCandidateEducation } from 'api/users';
import { updateCandidateWorkExperience } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { updateSkills } from 'api/users';

const UpdateReleventSkillsDrawer = ({
  onClose,
  workExperience,
  education,
  certificate,
  skillsUpdate,
  nonMatchingSkills,
  matchingSkills,
  setNonMatchingSkills,
}) => {
  const [matchedSkills, setMatchedChips] = useState(matchingSkills || []);
  const [selectedChips, setSelectedChips] = useState([]);
  const defaultValues = {
    skills: [],
    selectedWorkExperience: [],
    selectedEducation: [],
    selectedCertificate: [],
  };
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    watch,
  } = methods;

  useEffect(() => {
    setValue('skills', matchedSkills);
  }, [matchedSkills]);

  const [skillValue, setSkillValue] = useState();

  const onSubmit = handleSubmit(async (formData) => {
    try {
      formData.skills = [skillValue];
      if (
        formData?.selectedEducation?.length > 0 ||
        formData?.selectedWorkExperience?.length > 0 ||
        formData.selectedCertificate?.length > 0
      ) {
        const response = await updateSkills(formData);
        if (response && response?.data && response?.success) {
          setNonMatchingSkills(nonMatchingSkills.filter((chip) => chip !== selectedChips[0]));
          skillsUpdate(matchedSkills);
          setSelectedChips([]);
          onClose();
          enqueueSnackbar('  Skill updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to update skills.', { variant: 'warning' });
        }
      } else {
        enqueueSnackbar('Please check the box you want to update.', { variant: 'warning' });
      }
    } catch (error) {
      console.error('Error to set skills. Please try again.', error);
    }
  });

  const handleWorkExperienceChange = (experience) => (event) => {
    const uniqueIdentifier = experience._id;
    const selectedWorkExperience = getValues('selectedWorkExperience');
    if (event.target.checked) {
      setValue('selectedWorkExperience', [...selectedWorkExperience, experience]);
    } else {
      setValue(
        'selectedWorkExperience',
        selectedWorkExperience.filter((experience) => experience._id !== uniqueIdentifier)
      );
    }
  };

  const handleEducationChange = (educationItem) => (event) => {
    const uniqueIdentifier = educationItem._id;
    const selectedEducation = getValues('selectedEducation');
    if (event.target.checked) {
      setValue('selectedEducation', [...selectedEducation, educationItem]);
    } else {
      setValue(
        'selectedEducation',
        selectedEducation.filter((educationItem) => educationItem._id !== uniqueIdentifier)
      );
    }
  };

  const handleCertificateChange = (certificate) => (event) => {
    const uniqueIdentifier = certificate._id;
    const selectedCertificate = getValues('selectedCertificate');
    if (event.target.checked) {
      setValue('selectedCertificate', [...selectedCertificate, certificate]);
    } else {
      setValue(
        'selectedCertificate',
        selectedCertificate.filter((certificate) => certificate._id !== uniqueIdentifier)
      );
    }
  };

  const handleClick = (label) => {
    setSkillValue(label);
    if (selectedChips.includes(label)) {
      setSelectedChips(selectedChips.filter((chip) => chip !== label));
      setMatchedChips(matchedSkills.filter((chip) => chip !== label));
    } else {
      if (selectedChips?.length < 1) {
        setSelectedChips([...selectedChips, label]);
        setMatchedChips([...matchedSkills, label]);
      } else {
        enqueueSnackbar('You can select only one skills at a time.', { variant: 'warning' });
      }
    }
  };
  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container update-skills-drawer-container" role="presentation" >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-header">
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <IconButton aria-label="delete" onClick={onClose} sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z" fill="white" />
                </svg>
              </SvgIcon>
            </IconButton>
            <Typography className="title" sx={{ marginBottom: '0rem!important' }}>Skill Update</Typography>
          </Stack>
        </Box>

        <Box className="drawer-content update-skills-content">
          <Box className="skills-section" mb={1}>
            <FormControl fullWidth>
              <TextField
                value={skillValue}
                label="New Skill"
                size="small"
                variant="outlined"
                InputLabelProps={{
                  shrink: skillValue ? true : undefined,
                }}
                InputProps={{
                  endAdornment: skillValue && (
                    <InputAdornment position="end" sx={{ marginRight: 1 }}>
                      <IconButton
                        aria-label=""
                        edge="end"
                        onClick={() => setSkillValue('')}
                      >
                        <SvgIcon sx={{ width: '10px', height: '10px' }}>
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.833252 0.833008L9.16658 9.16634M9.16658 0.833008L0.833252 9.16634L9.16658 0.833008Z" stroke="#6B7280" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </SvgIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>

          <Box className="skills-section select-relevent-skills" mb={2} sx={{ paddingLeft: '10px' }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {nonMatchingSkills.map((label, index) => (
                  <FormControlLabel index={index} value={label} control={<Radio />} label={label} onClick={() => handleClick(label)} className={skillValue === label ? "selected-skill" : ''} />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box className="skills-section" mb={2}>
            {workExperience.length > 0 && (
              <Box>
                <Typography className="title">Experience</Typography>
                <Stack>
                  {workExperience.map(
                    (experience, index) =>
                      experience.organization_name !== 'Work Gap' && (
                        <Box>
                          <FormControlLabel
                            key={index}
                            disabled={experience?.skills?.length >= 8}
                            control={<Checkbox onChange={handleWorkExperienceChange(experience)} />}
                            label={`${experience?.highest_designation || ''} at ${experience?.organization_name || ''}`}
                          />
                          {experience?.skills?.length >= 8 && (
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              className="error-text"
                              size="small"
                              component="p"
                              style={{ color: 'red' }}
                              marginLeft={'30px'}
                            >
                              Max skill count exceeded.  Please update in Basic Profile page
                            </Typography>
                          )}
                        </Box>
                      )
                  )}
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="skills-section" mb={2}>
            {education?.length > 0 && (
              <Box>
                <Typography className="title">Education</Typography>
                <Stack>
                  {education.map((data, index) => (
                    <Box>
                      <FormControlLabel
                        key={index}
                        disabled={data?.skills?.length >= 8}
                        control={<Checkbox onChange={handleEducationChange(data)} />}
                        label={`${data?.degree_received || ''} at ${data?.education_institute_name || ''}`}
                      />
                      {data?.skills?.length >= 8 && (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="error-text"
                          size="small"
                          component="p"
                          style={{ color: 'red' }}
                          marginLeft={'30px'}
                        >
                          Max skill count exceeded.  Please update in Basic Profile page
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="skills-section">
            <Stack>
              {certificate?.length > 0 && (
                <Box>
                  <Typography className="title">Certification</Typography>
                  <Stack>
                    {certificate?.map((data, index) => (
                      <Box>
                        <FormControlLabel
                          key={index}
                          disabled={data?.skills?.length >= 8}
                          control={<Checkbox onChange={handleCertificateChange(data)} />}
                          label={`${data?.certificate_received || ''} at ${data?.education_institute_name || ''}`}
                        />
                        {data?.skills?.length >= 8 && (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            className="error-text"
                            size="small"
                            component="p"
                            style={{ color: 'red' }}
                            marginLeft={'30px'}
                          >
                            Max skill count exceeded.  Please update in Basic Profile page
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>

        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={isSubmitting}
              type="submit"
              className="update-btn"
            >
              Update
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default UpdateReleventSkillsDrawer;
